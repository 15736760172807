.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.modal {
  background-color: #111111;
  padding: 2.5rem;
  border-radius: 15px;
  width: 90%;
  max-width: 700px;
  color: white;
  text-align: center;
  position: relative;
  border: 1px solid #333;
  box-shadow: 0 0 40px rgba(76, 175, 80, 0.1);
  animation: slideUp 0.4s ease-out;
}

.modalHeader {
  margin-bottom: 2rem;
}

.title {
  font-size: 2rem;
  color: #4CAF50;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.topic {
  font-size: 1.2rem;
  margin: 1rem 0 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid #333;
}

.vsContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  margin-bottom: 2rem;
}

.vsText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: #4CAF50;
  background: #111;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4CAF50;
  z-index: 1;
}

.debater {
  flex: 1;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.debaterFor {
  background: rgba(58, 170, 51, 0.1);
  border: 1px solid #3a3;
}

.debaterAgainst {
  background: rgba(170, 51, 51, 0.1);
  border: 1px solid #a33;
}

.debaterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side {
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.sideFor {
  color: #3a3;
}

.sideAgainst {
  color: #a33;
}

.username {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.readyStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

.ready {
  color: #4CAF50;
}

.notReady {
  color: #888;
}

.readyButton {
  margin-top: 2rem;
  padding: 1rem 3rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  background: #4CAF50;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.readyButton:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
}

.readyButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.readyButton.ready {
  background: #1b5e20;
}

.timer {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: #888;
  font-size: 0.9rem;
}

.success {
  text-align: center;
  padding: 3rem 2rem;
}

.successIcon {
  color: #4CAF50;
  margin-bottom: 1.5rem;
  animation: pulseGreen 2s infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseGreen {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .vsContainer {
    flex-direction: column;
    gap: 3rem;
  }

  .vsText {
    top: 0;
    transform: translate(-50%, -50%);
  }
}