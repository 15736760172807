.supportPage {
    min-height: 100vh;
    background-color: black;
    color: white;
    font-family: 'Manrope', sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 48px 16px;
  }
  
  .headerSection {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .headerTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 16px;
    background: linear-gradient(90deg, #FFFFFF 0%, #4CAF50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .headerSubtitle {
    color: #999;
    font-size: 1.125rem;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    margin-bottom: 48px;
  }
  
  @media (min-width: 768px) {
    .gridContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 24px;
  }
  
  .cardTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .cardTitle svg {
    color: #4CAF50;
  }
  
  .cardText {
    color: #999;
    margin-bottom: 24px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .textarea {
    width: 100%;
    height: 128px;
    padding: 16px;
    border-radius: 8px;
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    font-family: 'Manrope', sans-serif;
    resize: vertical;
  }
  
  .textarea::placeholder {
    color: #666;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .button:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  .primaryButton {
    composes: button;
    background-color: #4CAF50;
    color: white;
  }
  
  .primaryButton:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .discordButton {
    composes: button;
    background-color: #5865F2;
    color: white;
    text-decoration: none;
  }
  
  .discordButton:hover {
    background-color: #4752C4;
  }
  
  .statusMessage {
    font-size: 0.875rem;
    margin-top: 8px;
  }
  
  .successMessage {
    composes: statusMessage;
    color: #4CAF50;
  }
  
  .errorMessage {
    composes: statusMessage;
    color: #ff4444;
  }
  
  .footer {
    text-align: center;
    color: #999;
  }
  
  .footerLink {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .footerLink:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 24px 16px;
    }
  
    .headerTitle {
      font-size: 2rem;
    }
  
    .headerSubtitle {
      font-size: 1rem;
    }
  }