.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #000000;
  color: #FFFFFF;
  font-family: 'Manrope', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #000000;
  margin-bottom: 0;
}

.headerMobile {
  font-size: 0.8rem;
}

.timerContainer, .finishPrepContainer {
  flex: 1;
}

.timerContainer {
  text-align: left;
}

.timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4CAF50;
}

.roleInfo {
  font-size: 1.2rem;
}

.role {
  font-weight: bold;
}

.oppRole {
  color: #FF4500;
}

.govRole {
  color: #4CAF50;
}

.finishPrepContainer {
  text-align: right;
}

.finishPrepButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.finishPrepButton:hover {
  background-color: #45a049;
}

.motionContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 0.5rem;
  padding-left: 50px;
  min-height: 80px;
}

.infoButton {
  position: absolute;
  left: 0;
  bottom: 5px;
  transform: none;
  background: rgba(128, 128, 128, 0.2);
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.infoTriangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid white;
}

.motion {
  font-size: 1.5rem;
  margin: 0.25rem;
  text-align: center;
  width: 100%;
}

.motionMobile {
  font-size: 1.2rem;
  margin: 0.5rem;
}

.infoPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.infoPopup.active {
  opacity: 1;
  visibility: visible;
}

.infoPopupContent {
  background-color: #1a1a1a;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  height: auto;
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.infoPopupContent h2 {
  color: #4CAF50;
  margin-bottom: 1rem;
}

.infoPopupContent ul {
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.infoPopupContent li {
  margin-bottom: 0.5rem;
}

.closeInfoButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.editorContainer {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  flex-grow: 1;
  overflow: hidden;
}

.formatBar {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  background-color: #1a1a1a;
  padding: 0.5rem;
  border-radius: 5px 5px 0 0;
}

.formatButton, .formatSelect {
  background-color: #333;
  color: #FFFFFF;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.formatButton:hover, .formatSelect:hover {
  background-color: #444;
}

.formatButton.active {
  background-color: #FFFFFF;
  color: #000000;
}

.formatSelect {
  appearance: none;
  padding-right: 1.5rem;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.editor {
  flex-grow: 1;
  background-color: #1a1a1a;
  color: #FFFFFF;
  border: none;
  border-radius: 0 0 5px 5px;
  padding: 1rem;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
  overflow-y: auto;
  line-height: 1.5;
  min-height: 0;
}

.editorMobile {
  min-height: 0;
}

.editor:focus {
  outline: none;
  box-shadow: 0 0 0 2px #4CAF50;
}

@media (min-width: 769px) {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .header {
    margin-bottom: 0;
  }

  .motion {
    margin-bottom: 0;
  }

  .editorContainer {
    max-height: calc(100vh - 180px);
  }

  .editor {
    max-height: calc(100% - 50px);
  }

  .infoPopupContent {
    animation: zoomIn 0.3s ease-out;
  }
}

@media (max-width: 768px) {
  .infoButton {
    position: static;
    margin-right: 0.5rem;
    transform: none;
    width: auto;
    bottom: auto;
  }

  .infoText {
    font-size: 0.7rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 2vh;
    box-sizing: border-box;
  }

  .header {
    flex: 0 0 auto;
    position: relative;
    background-color: #000000;
    z-index: 10;
    padding: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0rem;
  }

  .timerContainer, .roleInfo, .finishPrepContainer {
    flex: 0 1 auto;
    font-size: 1rem;
  }

  .roleInfo {
    text-align: center;
    margin: 0 0.5rem;
  }

  .timer, .roleInfo, .finishPrepButton {
    font-size: 1.1rem;
  }

  .finishPrepButton {
    padding: 0.15rem 0.3rem;
  }

  .motionContainer {
    flex: 0 0 auto;
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    min-height: unset;
  }

  .motion {
    font-size: 0.9rem;
    margin: 0.25rem 0;
  }

  .editorContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .formatBar {
    flex: 0 0 auto;
  }

  .editor {
    flex: 1 1 0;
    min-height: 0;
    height: 100%;
    overflow-y: auto;
  }

  .infoPopup {
    align-items: flex-start;
  }

  .infoPopupContent {
    height: 100%;
    width: 75%;
    max-width: 75%;
    min-height: unset;
    border-radius: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    padding: 2rem;
  }

  .infoPopup.active .infoPopupContent {
    transform: translateX(0);
  }

  .infoTriangleClose {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.infoBullets {
  list-style-type: none;
  padding: 0;
}

.infoBullets li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.infoBullets li::before {
  content: "•";
  color: #4CAF50;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}