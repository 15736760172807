.container {
    position: relative;
    flex-grow: 1;
    display: flex;
    height: 2.5rem;
  }
  
  .inputContainer {
    position: relative;
    flex-grow: 1;
  }
  
  .searchIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;  /* text-gray-400 */
  }
  
  .input {
    height: 100%;
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 1rem;
    background-color: #111827;  /* bg-gray-900 */
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    color: white;
    font-family: 'Manrope', sans-serif;
    outline: none; /* Add this */
  }
  
  .input::placeholder {
    color: #9ca3af;  /* placeholder-gray-400 */
  }
  
  .input:focus {
    outline: none;
    box-shadow: 0 0 0 2px #374151;  /* focus:ring-2 focus:ring-gray-700 */
  }

  input {
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
  
  .button {
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #374151;  /* bg-gray-700 */
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: white;
    font-family: 'Manrope', sans-serif;
    display: flex;
    align-items: center;
    transition: background-color 0.2s;
  }
  
  .button:hover {
    background-color: #4b5563;  /* hover:bg-gray-600 */
  }