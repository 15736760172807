.container {
    min-height: 100vh;
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'Manrope', sans-serif;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #999;
  }
  
  .error {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
  }
  
  .error h2 {
    color: #ff4444;
    margin-bottom: 1rem;
  }
  
  .error p {
    color: #999;
    max-width: 500px;
  }
  
  .debateContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  
  /* ReadyModal specific styles */
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #000000;
    border: 1px solid rgba(76, 175, 80, 0.3);
    border-radius: 10px;
    padding: 2.5rem;
    width: 90%;
    max-width: 500px;
    text-align: center;
    animation: slideIn 0.3s ease-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .modal h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .topic {
    font-size: 1.1rem;
    color: #999;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #111;
    border-radius: 5px;
    word-break: break-word;
  }
  
  .readyStates {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .readyState {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background-color: #111;
    border-radius: 5px;
    min-width: 150px;
    transition: all 0.3s ease;
  }
  
  .readyState.ready {
    background-color: rgba(76, 175, 80, 0.1);
    border: 1px solid #4CAF50;
  }
  
  .playerName {
    font-weight: 500;
    color: #fff;
  }
  
  .readyCheck {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #4CAF50;
  }
  
  .waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #111;
    border-radius: 5px;
    color: #999;
    min-width: 150px;
    font-style: italic;
  }
  
  .readyButton {
    padding: 0.75rem 2rem;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .readyButton:disabled {
    background-color: #1a1a1a;
    border: 1px solid #333;
    cursor: not-allowed;
  }
  
  .readyButton:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .success {
    text-align: center;
    padding: 2rem;
  }
  
  .success .successIcon {
    color: #4CAF50;
    margin-bottom: 1rem;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .success h2 {
    color: #4CAF50;
    margin-bottom: 0.5rem;
  }
  
  .success p {
    color: #999;
  }
  
  /* Future debate interface sections */
  .debateArea {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .sidePanel {
    width: 300px;
    background-color: #111;
    border-radius: 10px;
    padding: 1.5rem;
  }
  
  .mainContent {
    flex: 1;
    background-color: #111;
    border-radius: 10px;
    padding: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .modal {
      width: 95%;
      padding: 1.5rem;
    }
  
    .readyStates {
      flex-direction: column;
      gap: 1rem;
    }
  
    .readyState,
    .waiting {
      min-width: 100%;
    }
  
    .debateContainer {
      padding: 1rem;
    }
  
    .debateArea {
      flex-direction: column;
    }
  
    .sidePanel {
      width: 100%;
    }
  }