.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    padding: 0 1rem;
  }
  
  .arrowButton {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: #111827;  /* bg-gray-900 */
    color: white;
    transition: background-color 0.2s;
  }
  
  .arrowButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .arrowButton:not(:disabled):hover {
    background-color: #1f2937;  /* hover:bg-gray-800 */
  }
  
  .pageNumbers {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: 'Manrope', sans-serif;
  }
  
  .pageButton {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    background-color: #111827;  /* bg-gray-900 */
    color: white;
    font-family: 'Manrope', sans-serif;
  }
  
  .pageButton:hover {
    background-color: #1f2937;  /* hover:bg-gray-800 */
  }
  
  .activePage {
    background-color: white;
    color: black;
    font-weight: 500;
  }