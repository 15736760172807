.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
  }
  
  .modal {
    background-color: #111111;
    padding: 1.5rem 1.5rem 1rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    color: white;
    text-align: center;
    position: relative;
    border: 1px solid #333;
    box-shadow: 0 0 40px rgba(76, 175, 80, 0.1);
    animation: slideUp 0.4s ease-out;
  }
  
  .title {
    font-size: 1.5rem;
    color: #4CAF50;
    margin: 0 0 1.25rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: 'Manrope', sans-serif;
  }
  
  .previewContainer {
    margin-bottom: 1.5rem;
    padding: 0 0.75rem;
  }
  
  .topicContainer {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .topicLabel {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #888;
    margin-bottom: 0.25rem;
    letter-spacing: 1px;
    font-family: 'Manrope', sans-serif;
  }
  
  .topic {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    border: 1px solid #333;
    color: white;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
  }
  
  .vsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    max-width: 450px;
    position: relative;
    padding: 0.75rem 0;
  }
  
  .vsText {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4CAF50;
    color: #4CAF50;
    flex-shrink: 0;
  }
  
  .vsText svg {
    width: 16px;
    height: 16px;
  }
  
  .debater {
    flex: 1;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .debaterFor {
    background: rgba(58, 170, 51, 0.1);
    border: 1px solid #3a3;
  }
  
  .debaterAgainst {
    background: rgba(170, 51, 51, 0.1);
    border: 1px solid #a33;
  }
  
  .debaterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .side {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Manrope', sans-serif;
  }
  
  .sideFor {
    color: #3a3;
  }
  
  .sideAgainst {
    color: #a33;
  }
  
  .username {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
  }
  
  .urlContainer {
    margin-bottom: 1.25rem;
  }
  
  .urlLabel {
    color: #ccc;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    font-family: 'Manrope', sans-serif;
  }
  
  .urlInputContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0 0.75rem;
  }
  
  .urlInput {
    flex: 1;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    color: #ccc;
    font-size: 0.9rem;
    font-family: monospace;
  }
  
  .copyButton {
    background-color: #1a1a1a;
    border: 1px solid #333;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .copyButton:hover {
    background-color: #252525;
    border-color: #4CAF50;
  }
  
  .copyIcon {
    width: 1rem;
    height: 1rem;
    color: #ccc;
  }
  
  .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    font-family: 'Manrope', sans-serif;
  }
  
  .tooltip:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .shareButton {
    background-color: #2196F3;
    color: white;
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
  }
  
  .shareButton:hover {
    background-color: #1976D2;
  }
  
  .shareIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .mobileShareIcon {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(90deg);
  }
  
  .qrLabel {
    color: #888;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    font-family: 'Manrope', sans-serif;
  }
  
  .qrContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .qrCode {
    width: 150px;
    height: 150px;
    background-color: white;
    padding: 0.75rem;
    border-radius: 6px;
  }
  
  .mobileActions {
    margin-top: auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .cancelButton,
  .desktopCancelButton {
    background-color: #e53935;
    color: white;
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-family: 'Manrope', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 90%;
    margin: 0 auto;
    font-weight: 500;
  }
  
  .cancelButton:hover,
  .desktopCancelButton:hover {
    background-color: #d32f2f;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(229, 57, 53, 0.3);
  }
  
  .cancelIcon {
    width: 0.9rem;
    height: 0.9rem;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .overlay {
      background: #1a1a1a;
      overflow-y: auto;
    }
  
    .modal {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 1.5rem 1rem;
      border-radius: 0;
      border: none;
      min-height: auto;
    }
  
    .title {
      font-size: 1.25rem;
      margin: 0 0 1.5rem;
    }
  
    .previewContainer {
      padding: 0;
      margin-bottom: 1.5rem;
    }
  
    .vsContainer {
      gap: 0.75rem;
    }
  
    .urlContainer {
      margin-bottom: 1.5rem;
    }
  
    .qrCode {
      width: 120px;
      height: 120px;
    }
  
    /* Remove all the mobile-specific elements */
    .mobileActions {
      display: none;
    }
  }