.deleteAccountContainer {
    margin-top: 32px;
    border-top: 1px solid #333;
    padding-top: 32px;
  }
  
  .deleteButton {
    width: 100%;
    padding: 12px 16px;
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .deleteButton:hover {
    background-color: #ff2222;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #0D0D0D;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modalHeader {
    padding: 20px 24px;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modalHeader h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
  }
  
  .closeButton:hover {
    color: white;
  }
  
  .modalBody {
    padding: 24px;
  }
  
  .modalBody p {
    margin: 0 0 16px 0;
    color: #999;
  }
  
  .modalBody ul {
    list-style-type: disc;
    margin: 16px 0;
    padding-left: 24px;
    color: #999;
  }
  
  .modalBody li {
    margin-bottom: 8px;
  }
  
  .confirmSection {
    margin-top: 24px;
  }
  
  .confirmSection strong {
    color: white;
    font-weight: 600;
  }
  
  .confirmInput {
    width: 100%;
    padding: 8px;
    background: #1a1a1a;
    border: 1px solid #333;
    border-radius: 4px;
    color: white;
    margin-top: 8px;
  }
  
  .confirmInput:focus {
    outline: none;
    border-color: #666;
  }
  
  .modalFooter {
    padding: 20px 24px;
    border-top: 1px solid #333;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .cancelButton,
  .confirmDeleteButton {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .cancelButton {
    background-color: #333;
    color: white;
  }
  
  .cancelButton:hover {
    background-color: #444;
  }
  
  .confirmDeleteButton {
    background-color: #ff4444;
    color: white;
  }
  
  .confirmDeleteButton:hover {
    background-color: #ff2222;
  }
  
  .error {
    color: #ff4444;
    font-size: 14px;
    margin: 8px 0 0 0;
  }