.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .modalContainer {
    background-color: #000000;
    border: 1px solid #333;
    border-radius: 6px;
    width: 100%;
    max-width: 600px;
    margin-top: 2rem;
    position: relative;
  }
  
  .modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.5rem 0;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .modalTitle {
    font-size: 1.25rem;
    color: #FFFFFF;
    margin: 0;
  }
  
  .closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
  }
  
  .closeButton:hover {
    color: #FFFFFF;
  }
  
  .modalContent {
    padding: 1.5rem;
  }
  
  .formSection {
    margin-bottom: 1.5rem;
  }
  
  .formSection h3 {
    font-size: 1.1rem;
    color: #FFFFFF;
    margin-bottom: 0.75rem;
  }
  
  .visibilityContainer {
    position: relative;
    width: 100%;
  }
  
  .visibilityDropdown {
    width: 100%;
    padding: 0.6rem;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-family: 'Manrope', sans-serif;
    appearance: none;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .visibilityDropdown:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .visibilityDropdown:hover {
    border-color: #4CAF50;
    background-color: #222;
  }
  
  .dropdownIcon {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    pointer-events: none;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #333;
    margin: 1.5rem 0;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .modalOverlay {
      padding: 1rem;
    }
  
    .modalContainer {
      margin-top: 1rem;
    }
  
    .modalHeader {
      padding: 1rem 1rem 0;
    }
  
    .modalContent {
      padding: 1rem;
    }
  
    .modalTitle {
      font-size: 1.1rem;
    }
  
    .visibilityDropdown {
      padding: 0.5rem;
      font-size: 0.85rem;
    }
  }
  
  /* Additional styles for dark mode emphasis */
  .modalContainer {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .visibilityDropdown option {
    background-color: #1a1a1a;
    color: #FFFFFF;
    padding: 0.5rem;
  }
  
  .formSection h3 {
    font-weight: 500;
  }
  
  /* Focus styles for better accessibility */
  .closeButton:focus {
    outline: none;
    color: #FFFFFF;
  }
  
  .visibilityDropdown:focus-visible {
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }