/* UsernameModal.module.css */
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 50;
}

.modalContainer {
  background-color: black;
  border: 1px solid #1f2937;
  border-radius: 0.75rem;
  max-width: 28rem;
  width: 100%;
  padding: 1.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
}

.textContent {
  color: #d1d5db;
}

.currentUsername {
  background-color: #111827;
  border: 1px solid #374151;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 0.5rem;
}

.labelText {
  font-size: 0.875rem;
  color: #9ca3af;
}

.currentUsernameText {
  color: white;
  font-weight: 500;
}

.inputContainer {
  margin-top: 0.5rem;
}

.usernameInput {
  width: 100%;
  background-color: #111827;
  border: 1px solid #374151;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  transition: border-color 0.2s;
}

.usernameInput:focus {
  outline: none;
  border-color: #059669;
}

.errorText {
  font-size: 0.875rem;
  color: #f87171;
  margin-top: 0.5rem;
}

.submitButton {
  width: 100%;
  background-color: #059669;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  transition: background-color 0.2s;
}

.submitButton:hover:not(:disabled) {
  background-color: #047857;
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.guidelinesContainer {
  background-color: rgba(17, 24, 39, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.guidelinesTitle {
  font-size: 0.875rem;
  color: #9ca3af;
  font-weight: 500;
}

.guidelinesList {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 0.875rem;
  color: #9ca3af;
  margin-top: 0.5rem;
}

.guidelinesList li {
  margin-top: 0.25rem;
}