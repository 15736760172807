.pageContainer {
    min-height: 100vh;
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'Manrope', sans-serif;
    padding-top: 15px;
  }
  
  .contentContainer {
    max-width: 900px;
    margin: 0 auto;
    padding: 1.5rem;
  }
  
  .header {
    text-align: center;
    margin-bottom: 1.25rem;
  }
  
  .header h1 {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
  }
  
  .header p {
    color: #999;
    font-size: 0.95rem;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.75rem;
  }
  
  .searchContainer {
    flex: 1;
    position: relative;
    max-width: 600px;
  }
  
  .searchIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
  
  .searchInput {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.25rem;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 0.9rem;
    transition: border-color 0.3s;
  }
  
  .searchInput:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .createButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 36px;
  }
  
  .createButton:hover {
    background-color: #45a049;
  }
  
  .roomsList {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-top: 1rem;
  }
  
  .roomCard {
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 6px;
    padding: 0.75rem 1rem;
    transition: transform 0.2s, border-color 0.2s;
    cursor: pointer;
  }
  
  .roomCard:hover {
    transform: translateY(-1px);
    border-color: #4CAF50;
  }
  
  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .topicAndSide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  
  .roomTopic {
    font-size: 1rem;
    margin: 0;
    color: #FFFFFF;
    flex: 1;
  }
  
  .sideIndicator {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
    white-space: nowrap;
    min-width: 100px;
    justify-content: center;
  }
  
  .sideIndicator.against {
    background-color: rgba(58, 170, 58, 0.2);
    border: 1px solid #3a3;
    color: #3a3;
  }
  
  .sideIndicator.for {   
    background-color: rgba(170, 51, 51, 0.2);
    border: 1px solid #a33;
    color: #a33;
  }
  
  .sideIndicator.random {
    background-color: rgba(255, 170, 0, 0.2);
    border: 1px solid #ffaa00;
    color: #ffaa00;
  }
  
  .roomDetails {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 0.85rem;
    margin-top: 0.25rem;
  }
  
  .username {
    color: #4CAF50;
    font-weight: 600;
  }
  
  .time {
    font-weight: 600;
    transition: color 0.3s;
  }
  
  .expiryUrgent {
    color: #ff4444 !important;
  }
  
  .expirySoon {
    color: #ffaa00 !important;
  }
  
  .expiryNormal {
    color: #4CAF50 !important;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.4rem;
    margin-top: 1.25rem;
  }
  
  .pageButton {
    padding: 0.4rem 0.8rem;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 32px;
  }
  
  .pageButton:hover {
    background-color: #333;
  }
  
  .pageButton.activePage {
    background-color: #4CAF50;
    border-color: #4CAF50;
  }
  
  .createdBy, .expires {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .contentContainer {
      padding: 1rem;
    }
  
    .actionsContainer {
      flex-direction: column;
    }
  
    .searchContainer {
      max-width: 100%;
    }
  
    .createButton {
      width: 100%;
      justify-content: center;
    }
  
    .topicAndSide {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  
    .sideIndicator {
      width: 100%;
      justify-content: center;
    }
  
    .roomDetails {
      flex-direction: column;
      gap: 0.25rem;
    }
  }
  
  /* Animation for hovering over clickable elements */
  .roomCard, .createButton, .pageButton, .searchInput {
    transition: all 0.2s ease-in-out;
  }
  
  /* Optional: Add a subtle animation for the side indicator */
  .sideIndicator {
    transition: all 0.2s ease-in-out;
  }
  
  .sideIndicator:hover {
    transform: scale(1.02);
  }

  .sectionHeaderContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sectionHeader h2 {
    margin: 0;
  }
  
  .dropdownContainer {
    position: relative;
    min-width: 140px;
  }
  
  .dropdown {
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 0.9rem;
    cursor: pointer;
    transition: border-color 0.3s;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .dropdown:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .dropdown:hover {
    border-color: #4CAF50;
  }
  
  .dropdownIcon {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    width: 16px;
    height: 16px;
    pointer-events: none;
  }