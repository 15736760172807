.pageContainer {
  min-height: 100vh;
  background-color: #000000;
  padding: 20px;
}

.profileContainer {
  max-width: 800px;
  margin: 40px auto;
  color: white;
}

.profileBox {
  background: #0D0D0D;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profileHeader {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.profilePicture {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background-color: #1a1a1a;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploadOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.profilePicture:hover .uploadOverlay {
  opacity: 1;
}

.uploadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileNameStatus {
  flex-grow: 1;
}

.profileName {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: white;
}

.bioContainer {
  cursor: pointer;
}

.bioText {
  color: #999;
  margin: 0;
}

.bioInput {
  width: 100%;
  padding: 8px;
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  margin-bottom: 8px;
  color: white;
}

.bioButtons {
  display: flex;
  gap: 8px;
}

.bioButton {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: #333;
  color: white;
}

.saveBioButton {
  background-color: #1a1a1a;
}

.cancelBioButton {
  background-color: #333;
}

.profileInfo {
  border-top: 1px solid #333;
  padding-top: 24px;
}

.field {
  margin-bottom: 20px;
}

.field label {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
  color: #999;
}

.field p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.editForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usernameInput {
  width: 100%;
  padding: 8px;
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  color: white;
}

.buttonContainer {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.saveButton,
.cancelButton,
.editButton {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.saveButton {
  background-color: #1a1a1a;
  color: white;
}

.cancelButton {
  background-color: #333;
  color: white;
}

.editButton {
  background-color: transparent;
  color: #999;
  text-decoration: underline;
  padding: 0;
}

.error {
  color: #ff4444;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 18px;
  color: white;
}

/* Subscription-related styles */
.planContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.planType {
  color: #999;
  font-size: 14px;
}

.menuContainer {
  position: relative;
}

.menuButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #999;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuButton:hover {
  background-color: #1a1a1a;
}

.menuDropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 4px 0;
  min-width: 180px;
  z-index: 10;
}

.menuItem {
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  color: white;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
}

.menuItem:hover {
  background-color: #333;
}

.menuItem:disabled {
  color: #666;
  cursor: not-allowed;
}

.paymentAlert {
  margin-top: 8px;
  padding: 12px;
  background-color: #ff44441a;
  border: 1px solid #ff4444;
  border-radius: 4px;
  color: #ff4444;
  font-size: 14px;
}

/* Delete account section */
.deleteAccount {
  text-align: center;
  margin-top: 24px;
  color: #999;
}

.deleteAccount a {
  color: #999;
  text-decoration: none;
}

.deleteAccount a:hover {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.refundNotice {
  text-align: center;
  color: #999;
  margin-top: 16px;
  font-size: 14px;
}

.upgradeButton {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #4a9eff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.2s;
}

.upgradeButton:hover {
  background-color: #3d84d4;
}