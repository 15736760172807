.container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9fafb;
  }
  
  .content {
    text-align: center;
    padding: 2rem;
  }
  
  .heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .text {
    color: #4b5563;
  }