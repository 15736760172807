.evaluationReportCard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  color: #FFFFFF;
  font-family: 'Manrope', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.highlight {
  color: #4CAF50;
}

.button {
  background-color: transparent;
  border: 1px solid #4CAF50;
  color: #4CAF50;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #4CAF50;
  color: #000000;
}

.topic {
  margin-bottom: 15px;
  font-size: 1rem;
}

.chartContainer {
  margin-bottom: 20px;
  background-color: #111111;
  padding: 10px;
  border-radius: 5px;
  height: 250px;
}

.summaryContainer {
  margin-bottom: 20px;
  text-align: left;
}

.summaryContainer h1,
.summaryContainer h2,
.summaryContainer h3,
.summaryContainer h4,
.summaryContainer h5,
.summaryContainer h6 {
  color: #4CAF50;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.paragraph {
  margin-bottom: 10px;
}

.list {
  margin-left: 20px;
  margin-bottom: 10px;
  list-style-type: disc;
}

.listItem {
  margin-bottom: 5px;
}

.summaryContainer a {
  color: #4CAF50;
  text-decoration: none;
}

.summaryContainer a:hover {
  text-decoration: underline;
}

.summaryContainer code {
  background-color: #111111;
  padding: 2px 4px;
  border-radius: 3px;
}

.summaryContainer pre {
  background-color: #111111;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.summaryContainer blockquote {
  border-left: 3px solid #4CAF50;
  padding-left: 10px;
  margin-left: 0;
  color: #CCCCCC;
}

.signupPrompt {
  text-align: center;
  margin-top: 2rem;
}

.startButton {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 1rem 0;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.loginLink {
  color: #4CAF50;
  cursor: pointer;
}

.loginLink:hover {
  text-decoration: underline;
}

.governmentWinner {
  color: #22c55e; /* Tailwind's green-500 */
  font-weight: bold;
}

.oppositionWinner {
  color: #ef4444; /* Tailwind's red-500 */
  font-weight: bold;
}

/* Keep all your existing styles */
.sectionHeader {
  text-decoration: underline;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.paragraph {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.list {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.orderedList {
  list-style-type: decimal;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.listItem {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}