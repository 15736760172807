/* Add any necessary font imports */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap');

/* Reset button styles */
button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

/* Main container */
.friends-tab {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Search section */
.search-container {
  position: relative;
  display: flex;
  height: 2.5rem;
}

.search-input-container {
  position: relative;
  flex-grow: 1;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
}

.search-input {
  height: 100%;
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 1rem;
  background-color: #111827;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  color: white;
  font-family: 'Manrope', sans-serif;
  border: none;
}

.search-input::placeholder {
  color: #9CA3AF;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #374151;
}

.search-button {
  height: 100%;
  padding: 0 1rem;
  background-color: #374151;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: white;
  font-family: 'Manrope', sans-serif;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #4B5563;
}

/* Tabs section */
.tabs {
  display: flex;
  gap: 0.5rem;
}

.tab-button {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-family: 'Manrope', sans-serif;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #9CA3AF;
}

.tab-button:hover {
  color: #E5E7EB;
}

.tab-button.active {
  background-color: #1F2937;
  color: white;
}

.count-badge {
  padding: 0.125rem 0.5rem;
  background-color: #374151;
  color: #D1D5DB;
  font-size: 0.75rem;
  border-radius: 9999px;
  font-family: 'Manrope', sans-serif;
}

/* User list section */
.user-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 0; /* Enable text truncation in children */
}

/* User card */
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: rgba(17, 24, 39, 0.3);
  border-radius: 0.5rem;
  transition: all 0.2s;
  min-width: 0;
}

.user-card:hover {
  background-color: rgba(17, 24, 39, 0.4);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 0;
  flex: 1;
  margin-right: 1rem;
}

.avatar {
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  overflow: hidden;
  border: 1px solid #374151;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-details {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
}

.username-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 0;
    white-space: nowrap; /* Add this */
  }
  
.username {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    color: white;
    white-space: nowrap;
    /* Remove flex: 1 */
    line-height: 1;
  }
  
.flag {
    flex-shrink: 0;
    width: 1rem;
    height: 0.75rem;
    display: block;
    margin-top: 1px;
  }
  
.additional-info {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  min-width: 0;
}

.email {
  font-size: 0.875rem;
  color: #9CA3AF;
  font-family: 'Manrope', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bio {
  font-size: 0.875rem;
  color: #9CA3AF;
  font-family: 'Manrope', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-seen {
  font-size: 0.75rem;
  color: #6B7280;
  font-family: 'Manrope', sans-serif;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
}

.status-dot {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 9999px;
  flex-shrink: 0;
}

.status-dot.online {
  background-color: #10B981;
}

.status-dot.offline {
  background-color: #6B7280;
}

/* Actions section */
.actions-container {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.unsend-prompt,
.block-menu {
  position: absolute;
  right: 0;
  top: -3rem;
  background-color: #1F2937;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.unsend-button {
  font-size: 0.875rem;
  color: #9CA3AF;
  font-family: 'Manrope', sans-serif;
  white-space: nowrap;
  transition: color 0.2s;
}

.unsend-button:hover {
  color: white;
}

.block-button {
  font-size: 0.875rem;
  color: #EF4444;
  font-family: 'Manrope', sans-serif;
  white-space: nowrap;
  transition: color 0.2s;
}

.block-button:hover {
  color: #F87171;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.action-button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.action-button:hover {
  background-color: #1F2937;
}

.action-button.accept {
  color: #10B981;
}

.action-button.reject {
  color: #EF4444;
}

.action-button.remove {
  color: #EF4444;
}

.action-button.add {
  color: #3B82F6;
}

.action-button.pending {
  color: #10B981;
}

.action-button.more {
  color: #9CA3AF;
}

/* Loading and empty states */
.loading-message,
.empty-message {
  text-align: center;
  padding: 2rem 0;
  color: #9CA3AF;
  font-family: 'Manrope', sans-serif;
}

/* Disabled state styles */
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Focus styles */
button:focus,
input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #374151;
}

/* Additional utility classes */
.text-red-500 {
  color: #EF4444;
}

.text-red-400 {
  color: #F87171;
}

.text-blue-500 {
  color: #3B82F6;
}

.text-green-500 {
  color: #10B981;
}

.text-gray-400 {
  color: #9CA3AF;
}

.text-white {
  color: white;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .tabs {
    flex-wrap: wrap;
  }
  
  .tab-button {
    flex: 1;
    justify-content: center;
  }
}