@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

.landingPage {
  font-family: 'Manrope', sans-serif;
  background-color: black;
  color: white;
  min-height: 100vh;
  overflow-x: hidden;
}

.mainContent {
  margin-top: 60px;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}

/* Hero Section */
.heroSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.heroContent {
  text-align: center;
  margin-bottom: 60px;
}

.heroContent h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 16px;
  background: linear-gradient(90deg, #FFFFFF 0%, #4CAF50 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 20px;
  color: #999;
  margin-bottom: 24px;
}

.buttonGroup {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.buttonIcon {
  margin-right: 8px;
}

.greenButton, .playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.greenButton {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.greenButton:hover {
  background-color: #45a049;
}

.playButton {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.playButton:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Features Grid */
.featuresGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.featureCard {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.featureCard:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-4px);
  border-color: rgba(76, 175, 80, 0.5);
}

.onlineFeature {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.onlineFeature:hover {
  border-color: rgba(76, 175, 80, 0.4);
}

.featureIcon {
  display: inline-flex;
  padding: 16px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  width: 150px;
  height: 80px;
  align-items: center;
  justify-content: center;
}

.featureIcon svg {
  color: #4CAF50;
  width: 42px;
  height: 42px;
}

.newBadge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
}

.featureCard h3 {
  font-size: 20px;
  margin-bottom: 12px;
  color: white;
}

.featureCard p {
  font-size: 15px;
  line-height: 1.5;
  color: #999;
  margin: 0;
}

/* Persona Carousel */
.personaCarousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 48px;
}

.personaIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  transition: all 0.3s ease;
}

.centerPersona {
  z-index: 3;
  transform: scale(1.2);
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.2);
}

.sidePersona {
  filter: blur(1px) brightness(0.8);
  z-index: 2;
}

.leftPersona {
  transform: translateX(-25px) scale(0.95);
}

.rightPersona {
  transform: translateX(25px) scale(0.95);
}

/* Footer */
.footer {
  padding: 20px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footerLink {
  color: #888;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: white;
}

.footerSeparator {
  margin: 0 10px;
  color: #888;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .heroContent h1 {
    font-size: 40px;
  }

  .featuresGrid {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding: 0;
  }

  .heroSection {
    padding: 20px 16px;
    width: 100%;
    box-sizing: border-box;
  }

  .heroContent {
    width: 100%;
    margin-bottom: 40px;
  }

  .heroContent h1 {
    font-size: 32px;
    padding: 0 16px;
  }

  .subtitle {
    font-size: 18px;
    padding: 0 16px;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 16px;
  }

  .greenButton, .playButton {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .featuresGrid {
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .featureCard {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .featureCard h3 {
    font-size: 18px;
    padding: 0 8px;
  }

  .featureCard p {
    font-size: 14px;
    padding: 0 8px;
  }

  .featureIcon {
    width: 120px;
    height: 70px;
  }

  .featureIcon svg {
    width: 36px;
    height: 36px;
  }

  .personaCarousel {
    width: 120px;
    height: 40px;
  }

  .personaIcon {
    width: 36px;
    height: 36px;
  }

  .leftPersona {
    transform: translateX(-20px) scale(0.95);
  }

  .rightPersona {
    transform: translateX(20px) scale(0.95);
  }

  .footer {
    padding: 20px 16px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 380px) {
  .heroContent h1 {
    font-size: 28px;
  }

  .subtitle {
    font-size: 16px;
  }

  .featureCard {
    padding: 16px;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}


.lockedFeature {
  opacity: 0.5;
  background: rgba(255, 255, 255, 0.02) !important;
  position: relative;
}

.lockedFeature:hover {
  transform: none !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.lockContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  z-index: 10;
}

.lockIcon {
  color: #FFF !important;
  margin-bottom: 8px;
}

.lockLabel {
  font-size: 14px;
  color: #888;
  font-weight: 500;
}

*, *:before, *:after {
  box-sizing: border-box;
}



/* End of new styles */
