.message {
  padding: 5px 10px;
  margin: 5px 0;
  max-width: 70%;
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  position: relative;
}

.message-user-gov,
.message-assistant-gov {
  background-color: #3a3;
  align-self: flex-start;
  text-align: left;
}

.message-user-opp,
.message-assistant-opp {
  background-color: #a33;
  align-self: flex-end;
  text-align: left;
}

.content {
  word-break: break-word;
}

/* Markdown-specific styles */
.paragraph {
  margin: 1em 0;
  white-space: pre-wrap;  /* Preserves line breaks */
}

.unorderedList,
.orderedList {
  margin: 0.5em 0;
  padding-left: 2em;  /* Indentation for lists */
}

.listItem {
  margin: 0.25em 0;
}

/* Move controls to bottom */
.ttsControls {
  position: absolute;
  bottom: -24px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.message:hover .ttsControls {
  opacity: 1;
}

.ttsButton {
  background: rgba(0, 0, 0, 0.6);
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.2s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.ttsButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.speedControl {
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  padding: 2px 8px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  transition: all 0.2s ease;
}

.speedControl:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .message {
    font-size: 16px;
    max-width: 85%;
  }

  .ttsControls {
    bottom: -20px;
  }

  .ttsButton {
    width: 20px;
    height: 20px;
  }
}