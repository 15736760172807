.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #000000;
    padding: 2rem;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    font-family: 'Manrope', sans-serif;
  }
  
  .modalContent h2 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .subtext {
    color: #888888;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .signUpButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 1rem;
  }
  
  .signUpButton:hover {
    background-color: #45a049;
  }
  
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #888888;
    margin: 1rem 0;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #555;
  }
  
  .separator::before {
    margin-right: 0.5em;
  }
  
  .separator::after {
    margin-left: 0.5em;
  }
  
  .googleAuthButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 1rem;
  }
  
  .googleAuthButton:hover {
    background-color: #444;
  }
  
  .googleLogo {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
  
  .loginLink {
    font-size: 0.9rem;
    color: #888888;
  }
  
  .loginLink a {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .loginLink a:hover {
    text-decoration: underline;
  }