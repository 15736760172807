@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
  }
  
  .statsPage {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #515151, #000);
  }
  
  .statsContainer {
    position: relative;
    background-color: black;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 450px;
  }
  
  .backArrow {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    text-decoration: none;
    font-size: 24px;
    line-height: 1;
  }
  
  .title {
    margin: 10px 0 15px;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    font-family: "Roboto-Light";
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
  }
  
  .statItem {
    text-align: center;
  }
  
  .statItem h3 {
    font-size: 18px;
    margin-bottom: 2px;
    font-family: "Roboto-Light";
    color: white;
  }
  
  .statItem p {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
  }