@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

:root {
  --top-menu-height: 40px;
  /* Adjust this value to match your top menu bar height */
  --evaluation-bar-height: 10vh;
  --argument-form-height: 20vh;
}

body {
  font-family: 'Manrope', sans-serif;
  background-color: #000;
  color: #fff;
  margin: 0;
  margin-top: 40px;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Debate Arena */
.debate-arena {
  border: 2px solid #444;
  padding: 20px;
  flex: 1;
  animation: draw-border 2s linear forwards;
  background-color: #111;
  position: fixed;
  top: calc(60px + env(safe-area-inset-top));
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: #111;
  /* Adjust as needed */
}

/* Border correct
  padding correct
  top should be 20px
  display flex
  flex direction

*/

.chat-box {
  border: 1px solid #444;
  padding: 10px;
  height: calc(100vh - var(--top-menu-height) - var(--evaluation-bar-height) - 2vh - var(--argument-form-height));
  overflow-y: auto;
  margin-bottom: 25px;
  background-color: #222;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .chat-box {
    height: calc(93vh - var(--top-menu-height) - var(--evaluation-bar-height) - 2vh - var(--argument-form-height));
  }
}

@keyframes draw-border {
  0% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }

  25% {
    border-top: 2px solid #444;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }

  50% {
    border-top: 2px solid #444;
    border-right: 2px solid #444;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }

  75% {
    border-top: 2px solid #444;
    border-right: 2px solid #444;
    border-bottom: 2px solid #444;
    border-left: 2px solid transparent;
  }

  100% {
    border-top: 2px solid #444;
    border-right: 2px solid #444;
    border-bottom: 2px solid #444;
    border-left: 2px solid #444;
  }
}

.message {
  padding: 5px 10px;
  margin: 5px 0;
  max-width: 70%;
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  z-index: 1;
  /* position: relative;
  max-width: 70%;
  padding: 5px 10px; */
  /* border-radius: 8px; */
}

.message.user-for,
.message.assistant-for,
.message.assistant-gov,
.message.user-gov {
  background-color: #3a3;
  align-self: flex-start;
  text-align: left;
}

.message.user-against,
.message.assistant-against,
.message.assistant-opp,
.message.user-opp {
  background-color: #a33;
  align-self: flex-end;
  text-align: left;
}

.round-message {
  text-align: center;
  color: yellow;
  font-weight: bold;
  margin-bottom: 10px;
}

.argument-form {
  display: flex;
  position: sticky;
  height: 20%;
  bottom: 0;
  flex-shrink: 0;
}

.timer-display {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #fff;
}

#argument-input {
  flex: 2;
  padding: 10px;
  font-size: 16px;
  font-family: 'Manrope', sans-serif;
  box-sizing: border-box;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #222;
  color: #fff;
  overflow: auto;
}

#argument-input:focus {
  outline: none;
  border-color: #666;
}

#argument-input:focus {
  outline: none;
  border-color: #666;
}

.argument-form button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  border-radius: 5px;
}

.argument-form button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.end-debate {
  cursor: pointer;
  color: yellow;
  position: absolute;
  bottom: -7.5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

.end-debate:hover {
  color: #ff0;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
}

.recording-controls {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}

.record-button,
.stop-record-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.record-button:hover,
.stop-record-button:hover,
.send-button:hover,
.end-prep-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.record-button:disabled,
.send-button:disabled,
.end-prep-button:disabled {
  color: #666;
  cursor: not-allowed;
}

.stop-record-button {
  animation: pulse 1s infinite;
}

.message.system a:hover {
  text-decoration: underline;
}

.all-sources-container {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #222;
  border: 1px solid #444;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

.all-sources-container h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #ddd;
}

.sources-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.round-sources {
  background-color: #333;
  padding: 8px;
  border-radius: 4px;
}

.round-sources h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #aaa;
}

.round-sources ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.round-sources li {
  margin-bottom: 2px;
}

.round-sources a {
  color: #00bfff;
  text-decoration: none;
  font-size: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.round-sources a:hover {
  text-decoration: underline;
}

.additional-sources {
  grid-column: 1 / -1;
  margin-top: 10px;
}

.additional-sources-button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.additional-sources-button:hover {
  background-color: #555;
}

.hidden {
  display: none;
}

.timer-display {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #fff;
  background-color: rgba(34, 34, 34, 0.8);
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid #666;
  font-family: 'Manrope', sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.label-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.persona-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f0f0f0;
  padding: 2px;
}

@media (max-width: 768px) {
  .persona-avatar {
    width: 20px;
    height: 20px;
  }
  
  .user-avatar {
    width: 20px;
    height: 20px;
  }
  
  .label-content {
    gap: 4px;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.timer-blink {
  color: yellow;
  animation: blink 1s step-start infinite;
}

.card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.card-content {
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  width: 400px
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  align-items: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.cross-exam-arena {
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.cross-exam-sidebar {
  width: 30%;
  overflow-y: auto;
  border: 1px solid #444;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 1.5%;
  text-align: center;
}


.sidebar-toggle {
  display: none;
}

/* parent class for for and against labels on top of arena */
.arena-labels {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  font-weight: 600;
}

.topic-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
}

.topic-message {
  text-align: center;
  font-size: 13px;
  flex: 1;
}

.side-labels {
  color: white;
  font-weight: 600;
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 5px;
  min-width: 80px; /* Ensure consistent width */
}

.label-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.side-labels.examinee {
  background-color: purple;
  border: solid 1px purple;
  padding: 2px 4px;
}

.side-labels.examiner {
  background-color: #c18910;
  border: solid 1px #c18910;
  padding: 2px 4px;
}

.side-labels.for.active {
  color: #3a3;
}

.side-labels.against.active {
  color: #a33;
}

.bottom-controls {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reportModal {
  width: 90%;
  max-width: 600px;
}

.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #333;
  border-radius: 3px;
  background-color: #111;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.custom-checkbox:checked {
  background-color: #a33;
  border-color: #a33;
}

.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  color: white;
  font-size: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-checkbox:hover:not(:checked) {
  border-color: #444;
}


.reportRight {
  margin-left: auto;
}

.reportLeft {
  margin-right: auto;
  order: -1;
}

.more-options-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-options-button {
  background: #222;
  color: #f3ca40;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 6px 12px;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.more-options-button:hover {
  background-color: #333;
  border-color: #555;
}

.more-options-button:focus,
.options-menu-item:focus {
  outline: none; /* Removes the default blue focus ring */
  box-shadow: none; /* Removes any focus-related shadow */
}

/* Popup menu */
.options-menu {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: #222;
  border: 1px solid #444;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  z-index: 2000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.options-menu-item {
  background: transparent;
  border: none;
  color: #a33;
  padding: 0.5rem 0.75rem;
  text-align: left;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap; /* Ensure icon and text remain on one line */
}

.options-menu-item:hover {
  background: #333;
}

.evaluation-bar-container {
  margin-top: -1em;
}

.evaluation-bar {
  height: 20px;
  background-color: #a33;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.evaluation-bar-fill {
  height: 100%;
  background-color: #3a3;
  transition: width 0.5s ease-in-out;
  position: relative;
}

.evaluation-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #aaa;
  padding: 15px 0px;
  overflow: hidden;
  font-size: 0.9em;
}

.evaluation-summary-text {
  line-height: 1.2;
  max-height: 100%;
  overflow: hidden;
}

.dynamic-boxes {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--argument-form-height));
}


.score-display-for {
  left: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  padding: 0 5px;
}

.score-display-against {
  right: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  padding: 0 5px;
}

@media screen and (max-width: 768px) {
  .cross-exam-sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100%;
    background-color: #1e1e1e;
    z-index: 1000;
    transition: left 0.3s ease-in-out;
    margin-right: 0;
  }

  .cross-exam-sidebar.sidebar-open {
    left: 0;
    top: 60px;
  }

  .sidebar-toggle {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1001;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding-top: 5px;
    padding-left: 10px
  }

  .cross-exam-arena {
    width: 100%;
  }
}

/* Add to your existing CSS */
.ai-assist-button {
  position: absolute;
  bottom: calc(var(--argument-form-height) + 25px);
  right: 30px;
  background-color: #333;
  color: #fff;
  border: 2px solid rgba(76, 175, 80, 0.3);
  /* #4CAF50 with 30% opacity */
  border-radius: 5px;
  padding: 8px 12px;
  font-family: 'Manrope', sans-serif;
  font-size: 1.9vh;
  width: 22vh;
  height: 10vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.ai-assist-button:hover {
  background-color: #444;
  border-color: rgba(76, 175, 80, 0.5);
  /* Slightly more opaque on hover */
}

.ai-assist-button:active {
  background-color: #555;
}

.ai-assist-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.message-container {
  position: relative;
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.message-container.for {
  flex-direction: row;
}

.message-container.against {
  flex-direction: row-reverse;
}

.argumentIndicator {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 10px;
  z-index: 100;
}

.perfectResponse {
  color: #4CAF50;
  transition: transform 0.2s ease;
}

.dropCount {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: help;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 6px;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.dropIcon {
  color: #FFA500;
  transition: transform 0.3s ease;
}

.dropNumber {
  font-size: 12px;
  font-weight: bold;
  color: #FFA500;
}

.dropTooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .dropTooltip {
    position: absolute;
    width: 250px;
    top: calc(100% + 10px);
  }

  .message-container.for .dropTooltip {
    left: 0;
  }

  .message-container.against .dropTooltip {
    right: 0;
  }
}

@media (max-width: 767px) {
  .dropTooltip {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 15vh;
    height: auto;
    max-height: 60vh;
    margin: 0;
    padding: 0;
    z-index: 2000;
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  }

  /* Add backdrop */
  .dropCount:hover::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1500;
  }

  .tooltipContent {
    padding: 20px;
    overflow-y: auto;
    max-height: 60vh;
    box-sizing: border-box;
    width: 100%;
  }

  .dropTooltip h4 {
    font-size: 16px;
    margin: 0 0 12px 0;
    color: #FFA500;
    position: sticky;
    top: 0;
    background: #1a1a1a;
    padding-bottom: 8px;
    border-bottom: 1px solid #333;
  }

  .dropTooltip ul {
    margin: 0;
    padding-left: 20px;
  }

  .dropTooltip li {
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.4;
  }

  /* Ensure the rest of the mobile styles work as intended */
  .argumentIndicator {
    padding: 4px;
  }

  .dropCount {
    padding: 4px 8px;
  }

  .dropNumber {
    font-size: 14px;
  }
}

.dropCount:hover .dropTooltip {
  display: block;
}

.tooltipContent {
  padding: 12px;
  position: relative;
}

.dropTooltip h4 {
  margin: 0 0 8px 0;
  color: #FFA500;
  font-size: 14px;
}

.dropTooltip ul {
  margin: 0;
  padding-left: 16px;
  list-style-type: disc;
}

.dropTooltip li {
  color: #fff;
  margin-bottom: 4px;
  font-size: 12px;
}

/* Animation keyframes */
@keyframes popIn {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(0.9);
  }

  85% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulseIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply animations */
.popIn {
  animation: popIn 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.bounceIn {
  animation: bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.pulseIn {
  animation: pulseIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

/* Interactive hover effects */
.perfectResponse:hover {
  transform: scale(1.2) rotate(360deg);
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.dropCount:hover {
  transform: scale(1.05);
}

.dropCount:hover .dropIcon {
  animation: spin 1s ease;
}

.perfectResponse .lucide-check-circle {
  color: #4CAF50;
  filter: drop-shadow(0 0 2px rgba(76, 175, 80, 0.3));
}

/* iPad Portrait Mode Specific Styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .message {
    font-size: 22.5px;
    /* 25% larger than original 18px */
  }

  .topic-message {
    font-size: 16.25px;
    /* 25% larger than original 13px */
  }

  .round-message {
    font-size: 17.5px;
    /* 25% larger */
  }

  #argument-input {
    font-size: 20px;
    /* 25% larger than original 16px */
  }

  .argument-form button {
    font-size: 20px;
    /* 25% larger than original 16px */
  }

  .timer-display {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  .end-debate {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  .evaluation-summary-text {
    font-size: 1.1em;
    /* 25% larger than base size */
  }

  .all-sources-container {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  .all-sources-container h3 {
    font-size: 20px;
    /* 25% larger than original 16px */
  }

  .round-sources h4 {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  .round-sources a {
    font-size: 15px;
    /* 25% larger than original 12px */
  }

  .additional-sources-button {
    font-size: 15px;
    /* 25% larger than original 12px */
  }

  .score-display-for,
  .score-display-against {
    font-size: 18.75px;
    /* 25% larger than original 15px */
  }

  .side-labels {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  /* Adjust cross-exam sidebar text */
  .cross-exam-sidebar h3 {
    font-size: 20px;
    /* 25% larger */
  }

  .cross-exam-sidebar p {
    font-size: 17.5px;
    /* 25% larger */
  }


  /* Message indicators */
  .dropNumber {
    font-size: 15px;
    /* 25% larger than original 12px */
  }

  .dropTooltip h4 {
    font-size: 17.5px;
    /* 25% larger than original 14px */
  }

  .dropTooltip li {
    font-size: 15px;
    /* 25% larger than original 12px */
  }
}


.vsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin: 2rem 0;
  gap: 2rem;
}

.debater {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.debaterFor {
  border: 1px solid rgba(58, 170, 58, 0.2);
}

.debaterAgainst {
  border: 1px solid rgba(170, 58, 58, 0.2);
}

.debaterHeader {
  margin-bottom: 0.5rem;
}

.side {
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.sideFor {
  background: rgba(58, 170, 58, 0.2);
  color: #3a3;
}

.sideAgainst {
  background: rgba(170, 58, 58, 0.2);
  color: #a33;
}

.username {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}

.vsText {
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.readyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 0.5rem;
  background: #4CAF50;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.readyButton:hover:not(:disabled) {
  background: #45a049;
  transform: translateY(-2px);
}

.readyButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.readyStatus {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4CAF50;
  font-size: 1rem;
  font-weight: 600;
}

.waitingText {
  color: #666;
  font-size: 1rem;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {

  .options-menu {
    bottom: 60px;
    right: 10px;
    left: auto;
    transform: none;
    min-width: 120px;
  }

  .vsContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .reportModal {
    max-width: 90%;
    width: 90%;
  }

  .reportButton {
    font-size: 0.7rem;
    padding: 3px 6px;
  }

  .debater {
    width: 100%;
    padding: 1rem;
  }

  .vsText {
    transform: rotate(90deg);
  }

  .username {
    font-size: 1rem;
  }

  .evaluation-summary {
    font-size: 0.8em;
  }

  .score-display-for,
  .score-display-against {
    font-size: 12px;
  }

  .message {
    font-size: 15px;
  }

  .topic-message {
    font-size: 12px;
    padding: 0px 5px;
  }

  .side-labels {
    padding: 0px;
    min-width: auto; /* Remove min-width on mobile */
  }

  .topic-row {
    padding: 0px;
  }

  .label-content {
    gap: 5px;
  }

  /* Hide the non-user side on mobile using CSS as well */
  /* .side-labels:not(.active) {
    display: none !important;
  } */

}

.gold-glow .message {
  border: 1px solid rgba(255, 215, 0, 0.4);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  animation: goldPulse 2s infinite;
}

@keyframes goldPulse {
  0% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
}

.ready-phase-timer {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(34, 34, 34, 0.95);
  padding: 8px 16px;
  border-radius: 15px;
  border: 1px solid #444;
  font-family: 'Manrope', sans-serif;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ready-phase-timer.warning {
  color: #f3ca40;
  border-color: #f3ca40;
  animation: pulse 2s infinite;
}

.ready-phase-timer.critical {
  color: #ff4444;
  border-color: #ff4444;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* iPad Portrait Mode */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .ready-phase-timer {
    font-size: 1.375rem;
    padding: 10px 20px;
  }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
  .ready-phase-timer {
    font-size: 0.9rem;
    padding: 6px 12px;
    top: -30px;
  }
}