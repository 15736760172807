.smartBanner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #4CAF50;
    color: white;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .content {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
  }
  
  .text {
    margin: 0;
    font-size: 14px;
  }
  
  .downloadButton {
    background: white;
    color: #4CAF50;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }
  
  .downloadButton:hover {
    background: #f0f0f0;
  }
  
  .closeButton {
    background: transparent;
    border: none;
    color: white;
    padding: 4px;
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease;
  }
  
  .closeButton:hover {
    opacity: 0.8;
  }
  
  /* This spacer ensures content isn't hidden behind the banner */
  .bannerSpacer {
    height: 56px; /* Should match banner height */
    display: none;
  }
  
  /* Only show spacer when banner is visible */
  .smartBanner + .bannerSpacer {
    display: block;
  }
  
  /* Mobile optimizations */
  @media (max-width: 480px) {
    .smartBanner {
      padding: 10px 12px;
    }
    
    .text {
      font-size: 13px;
    }
  
    .downloadButton {
      padding: 6px 12px;
      font-size: 13px;
    }
  }