.privacyPolicyPage {
    background-color: #121212;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: #e0e0e0;
    font-family: Arial, sans-serif;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .content h1 {
    color: #ffffff;
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .content h2 {
    color: #ffffff;
    font-size: 1.8em;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .content p {
    margin-bottom: 15px;
  }
  
  .content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .content li {
    margin-bottom: 10px;
  }
  
  .content section {
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .content {
      padding: 15px;
    }
  
    .content h1 {
      font-size: 2em;
    }
  
    .content h2 {
      font-size: 1.5em;
    }
  }