.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.content h1 {
  font-size: 2rem;
  margin-bottom: 0px;
  color: #ffffff;
  text-transform: lowercase;
  font-family: 'Manrope', sans-serif;
  text-align: center;
}

.disclaimer {
  font-size: 0.8rem;
  color: #888888;
  text-align: center;
  margin-bottom: 20px;
  max-width: 1200px;
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.input {
  flex-grow: 1;
  padding: 15px;
  background-color: #1a1a1a;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  resize: vertical;
  font-family: 'Manrope', sans-serif;
  outline: none;
  margin-right: 10px;
}

.button {
  padding: 15px 30px;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: lowercase;
  white-space: nowrap;
}

.button:hover:not(:disabled) {
  background-color: #45a049;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  color: #f44336;
  margin-top: 15px;
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
}

.loading {
  color: #888888;
  margin-top: 15px;
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
}

.imageContainer {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.factCheckImage {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  border-radius: 10px;
}

.factCheckPage {
  position: relative;
}

.content {
  transition: filter 0.3s ease-in-out;
}

.content.blurred {
  filter: blur(5px);
}

@media (max-width: 768px) {
  .content {
    padding: 40px 15px;
  }

  .content h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .disclaimer {
    font-size: 0.7rem;
    margin-bottom: 30px;
  }

  .inputContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .input {
    margin-right: 0;
    margin-bottom: 15px;
    font-size: 0.9rem;
    width: 98%;
    box-sizing: border-box;
    align-self: center;
  }

  .button {
    width: 98%;
    font-size: 0.9rem;
    padding: 12px 20px;
    align-self: center;
  }

  .imageContainer {
    max-width: 100%;
  }

  .factCheckImage {
    max-height: 250px;
  }
}