.popupOverlay {
    position: fixed;
    inset: 0;
    z-index: 50;
  }
  
  .popupBackdrop {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
  }
  
  .popupContainer {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .popupContent {
    position: relative;
    width: 100%;
    max-width: 42rem;
    background-color: black;
    border: 1px solid #7289DA;
    border-radius: 0.75rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
    padding: 2rem;
  }
  
  .popupClose {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #9ca3af;
    cursor: pointer;
  }
  
  .popupInner {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .discordLogo {
    margin-bottom: 0rem;
  }
  
  .discordLogo svg {
    width: 3rem;
    height: 3rem;
    color: #7289DA;
  }
  
  .popupTitle {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.0rem;
    margin-top: 1rem;
  }
  
  .popupDescription {
    color: #d1d5db;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1.25rem;
  }
  
  .discordButton {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #7289DA;
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    background-color: 0.2s;
    text-decoration: none;
  }
  
  .discordButton:hover {
    background-color: #5b6eaf;
  }