.pricingPage {
    /* Regal Gold Palette */
    --gold-primary: #E6B800;
    /* Deep Regal Gold */
    --gold-secondary: #FFD700;
    /* Classic Bright Gold Accent */
    --gold-shine: #F9E79F;
    /* Soft Shine Highlight */
    --gold-glow: rgba(200, 162, 2, 0.05);
    /* Soft Glow around Gold elements */
    --save-green: #4CAF50;
    /* Added for discount saving color */

    min-height: 100vh;
    background: radial-gradient(circle, #000000 0%, #0a0a0a 100%);
    padding-top: 20px;
    color: #fefefe;
    font-family: 'Manrope', sans-serif;
    display: flex;
    flex-direction: column;
}

/* Layout */
.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

/* Hero Section */
.heroText {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    margin: 1rem 0;
    letter-spacing: -0.02em;
    line-height: 1.2;
}

/* Highlight Text */
.highlight {
    background: linear-gradient(135deg, var(--gold-primary), var(--gold-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
    display: inline-block;
    position: relative;
    animation: highlightPulse 10s infinite;
    text-shadow: 0 0 10px var(--gold-glow), 0 0 20px var(--gold-secondary);
}

@keyframes highlightPulse {

    0%,
    100% {
        text-shadow: 0 0 10px var(--gold-glow), 0 0 20px var(--gold-secondary);
    }

    50% {
        text-shadow: 0 0 15px var(--gold-secondary), 0 0 30px var(--gold-secondary);
    }
}

/* Toggle Section */
.toggleContainer {
    display: flex;
    justify-content: center;
    margin: 0.75rem 0 1.25rem;
}

.toggleGroup {
    background-color: rgba(26, 26, 26, 0.6);
    padding: 0.25rem;
    border-radius: 0.5rem;
    display: inline-flex;
    border: 1px solid #333;
}

.toggleButton {
    padding: 0.65rem 1.4rem;
    border-radius: 0.375rem;
    transition: all 0.3s ease;
    border: none;
    background: none;
    color: #ccc;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 600;
    position: relative;
    outline: none !important;  /* Force no outline */
    -webkit-tap-highlight-color: transparent;  /* Remove tap highlight on mobile */
}

.toggleButton:focus {
    outline: none !important;
}

/* Optional: only show focus styles when using keyboard navigation */
.toggleButton:focus:not(:focus-visible) {
    outline: none !important;
    box-shadow: none !important;
}

.active {
    background: #1a1a1a;
}

.discount {
    position: absolute;
    top: -12px;
    right: -8px;
    background: var(--gold-secondary);
    color: #000;
    font-size: 0.7rem;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: bold;
}

/* Pricing Cards */
.pricingCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    max-width: 1100px;
    margin: 0.75rem auto 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease-out;
}

.pricingCards.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Base Card */
.pricingCard {
    background-color: rgba(17, 17, 17, 0.6);
    border-radius: 1rem;
    padding: 2rem;
    border: 1px solid #333;
    position: relative;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
}

.pricingCard:hover {
    transform: translateY(-4px);
}

/* Free Card */
.pricingCard.free .currentPrice {
    font-size: 2.25rem;
    font-weight: 800;
    color: #fefefe;
}

/* Gold Card */
.gold {
    background: linear-gradient(165deg, #0e0c05 0%, #000000 100%);
    border: 1px solid var(--gold-primary);
    box-shadow: 0 0 20px var(--gold-glow);
    animation: borderGlow 4s infinite ease-in-out;
}

@keyframes borderGlow {

    0%,
    100% {
        box-shadow: 0 0 20px var(--gold-glow);
    }

    50% {
        box-shadow: 0 0 30px var(--gold-glow);
    }
}

/* Card Badge */
.cardBadge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: linear-gradient(135deg, var(--gold-primary), var(--gold-secondary));
    color: black;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.3rem 0.8rem;
    border-radius: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 8px var(--gold-glow);
}

.earlyAccessBadge {
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    font-size: 0.75rem;
    color: var(--gold-primary);
    font-weight: 600;
}

/* Card Header */
.cardHeader {
    margin-bottom: 1rem;
}

.planName h3 {
    font-size: 1.75rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    text-align: left;
}


.goldTitle {
    color: var(--gold-primary);
    text-shadow: 0 0 20px var(--gold-glow);
}

.planSubtitle {
    color: #999;
    font-size: 1rem;
}

/* Price Comparison */
.pricingComparison {
    display: flex;
    align-items: center;
    gap: 1rem; /* Reduced from 1rem */
    border-radius: 0.75rem;
    /* padding: 1rem; */
    margin: 1rem 0;
    text-align: left;
}

.originalPrice {
    font-size: 1.25rem;
    font-weight: 600;
    color: #666;
    position: relative;
    text-decoration: line-through;
    text-decoration-color: var(--gold-primary);
    text-decoration-thickness: 2px;
}

.regularPrice {
    text-align: right;
}

.savingsArrow {
    color: var(--gold-secondary);
    font-size: 1.25rem;
    font-weight: bold;
}

.discountedPrice {
    text-align: left;
}

.currentPrice {
    font-size: 1.75rem;
    font-weight: 800;
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
}

.gold .currentPrice {
    font-size: 1.75rem;
    background: linear-gradient(135deg, var(--gold-primary), var(--gold-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.savingsTag {
    font-size: 0.875rem;
    color: var(--gold-secondary);
    font-weight: 600;
    margin-top: 0.25rem;
}

.period {
    font-size: 1rem;
    color: #999;
    font-weight: normal;
}

.gold .period {
    -webkit-text-fill-color: #999;
}

/* Features */
.featureList {
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
    list-style: none;  /* Remove bullets */
    padding: 0;        /* Remove default padding */
    margin-left: 0;    /* Remove default left margin */
}

.featureItem {
    display: flex;
    align-items: flex-start;  /* Changed from 'left' to 'flex-start' */
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    text-align: left;
    padding-left: 0;  /* Ensure no padding on individual items */
}

/* Adjust spacing above the feature list for gold card */
.gold .featureList {
    margin-top: 0rem;
    margin-bottom: 2.5rem;
}

.featureIcon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.featureIncluded {
    color: #fefefe;
}

.featureExcluded {
    color: #666;
}

/* Icons */
.goldIcon {
    color: var(--gold-secondary);
}

.checkIcon {
    color: var(--gold-secondary);
}

/* For the free plan's check icons, we now use green */
.checkIconFree {
    color: #4CAF50 !important;
}

.xIcon {
    color: #666;
}

.currentLabel {
    color: #999;
    text-align: center;
    font-size: 0.9rem;
    margin-top: auto;
    padding-top: 1rem;
}

.upgradeButton {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(135deg, var(--gold-primary), var(--gold-secondary));
    color: black;
    border: none;
    border-radius: 0.5rem;
    font-weight: 700;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-top: auto;
    position: relative;
    overflow: hidden;
}

.upgradeButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px var(--gold-glow);
}

.upgradeButton::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg,
            transparent,
            transparent 40%,
            rgba(255, 255, 255, 0.3) 40%, /* Increased from 0.1 */
            transparent 60%,
            transparent);
    transform: rotate(45deg);
    animation: buttonShine 5s infinite;
}

.featureItem strong {
    font-weight: 700;
    color: var(--gold-primary);
}

@keyframes buttonShine {
    0% {
        transform: translateX(-100%) rotate(45deg);
    }

    100% {
        transform: translateX(100%) rotate(45deg);
    }
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.buttonIcon {
    width: 1.25rem;
    height: 1.25rem;
}

/* Particles */
.particles {
    position: absolute;
    inset: -10px;
    pointer-events: none;
    overflow: hidden;
}

.particle {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--gold-primary);
    filter: blur(2px);
    box-shadow: 0 0 10px var(--gold-glow);
    animation: floatParticle 4s infinite ease-out;
}

@keyframes floatParticle {

    0%,
    100% {
        opacity: 0;
        transform: translateY(0);
    }

    25%,
    75% {
        opacity: 0.8;
    }

    50% {
        opacity: 1;
        transform: translateY(-15px);
    }
}

.particle:nth-child(1) {
    top: 5%;
    left: -5px;
}

.particle:nth-child(2) {
    top: 5%;
    right: -5px;
    animation-delay: 0.5s;
}

.particle:nth-child(3) {
    top: 35%;
    left: -5px;
    animation-delay: 1s;
}

.particle:nth-child(4) {
    top: 35%;
    right: -5px;
    animation-delay: 1.5s;
}

.particle:nth-child(5) {
    top: 65%;
    left: -5px;
    animation-delay: 2s;
}

.particle:nth-child(6) {
    top: 65%;
    right: -5px;
    animation-delay: 2.5s;
}

.particle:nth-child(7) {
    top: 95%;
    left: -5px;
    animation-delay: 3s;
}

.particle:nth-child(8) {
    top: 95%;
    right: -5px;
    animation-delay: 3.5s;
}

/* Features Highlight */
.featuresHighlight {
    margin-top: 6rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
}

@media (min-width: 768px) {
    .featuresHighlight {
        grid-template-columns: 1fr 1fr;
    }
}

.highlightItem {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.highlightTitle {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.highlightDescription {
    color: #999;
    line-height: 1.6;
}

.highlightImage {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #333;
}

/* FAQ Section */
.faqSection {
    margin-top: 6rem;
}

.faqTitle {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--gold-primary);
}

.faqGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .faqGrid {
        grid-template-columns: 1fr 1fr;
    }
}

.faqItem h4 {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.faqItem p {
    color: #999;
}

/* Footer */
.footer {
    margin-top: 6rem;
    border-top: 1px solid #1a1a1a;
    padding: 2rem;
    text-align: center;
    color: #666;
    font-size: 0.875rem;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .heroText {
        font-size: 2rem;
        padding: 0 1rem;
    }

    .pricingCards {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .pricingCard.gold {
        order: -1;
    }

    .pricingCard {
        padding: 1.5rem;
    }

    .toggleButton {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .gold .currentPrice {
        font-size: 2rem;
    }

    .cardBadge {
        font-size: 0.7rem;
        padding: 0.25rem 0.6rem;
    }

    .upgradeButton {
        font-size: 1rem;
        padding: 0.875rem;
    }
}

/* Additional Logo Icon style */
.logoIcon {
    height: 40px;
    width: auto;
    object-fit: cover;
    vertical-align: top;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}