@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

.auth-page {
  font-family: 'Manrope', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  padding: 2rem;
  box-sizing: border-box;
  color: white;
  position: relative;
}

.error-message {
  color: #ff6b6b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  text-align: left;
}

.auth-content {
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.auth-content .header-image {
  max-width: 100px;
  margin-bottom: 1rem;
}

.auth-content h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.auth-content .gray-subtitle {
  color: #999;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.auth-form .input-container {
  position: relative;
  margin-bottom: 0.5rem;
}

.auth-form input {
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid #999;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  font-family: 'Manrope', sans-serif;
  transition: border-color 0.3s;
}

.auth-form input:focus {
  outline: none;
  border-bottom-color: white;
}

.auth-form input::placeholder {
  color: #999;
  font-family: 'Manrope', sans-serif;
}

.auth-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Manrope', sans-serif;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.auth-button:hover {
  background-color: #45a049;
}

.auth-link {
  margin-top: 1rem;
  color: #999;
}

.auth-link a {
  color: #4CAF50;
  text-decoration: none;
}

.auth-link a:hover {
  text-decoration: underline;
}

.disclaimer {
  margin: -12px 0;
  font-size: 0.8rem;
  color: #999;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #555;
}

.separator::before {
  margin-right: 0.5em;
}

.separator::after {
  margin-left: 0.5em;
}

.google-auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: 'Manrope', sans-serif;
  transition: background-color 0.3s;
  margin: 0 auto;
  width: 80%;
}

.google-auth-button:hover {
  background-color: #444;
}

.google-logo {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #222;
  padding: 2rem;
  border-radius: 5px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.modal h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal p {
  margin-bottom: 1.5rem;
  color: #999;
}

.modal .auth-button {
  margin-top: 0;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  color: #4CAF50;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Manrope', sans-serif;
  transition: color 0.3s;
}

.back-button:hover {
  color: #45a049;
}

@media (max-width: 768px) {
  .auth-content {
    padding: 1rem;
  }

  .auth-content h3 {
    font-size: 1.5rem;
  }

  .auth-content .gray-subtitle {
    font-size: 1rem;
  }

  .back-button {
    top: 10px;
    left: 10px;
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}