.evaluationReportCardRooms {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 75vh;
  overflow: hidden;
  z-index: 1000;
  color: #FFFFFF;
  font-family: 'Manrope', sans-serif;
  display: flex;
  flex-direction: column;
}

.evaluationReportCard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  z-index: 1000;
  color: #FFFFFF;
  font-family: 'Manrope', sans-serif;
  display: flex;
  flex-direction: column;
}
  
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
  
.title {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}
  
.highlight {
  color: #4CAF50;
}
  
.button {
  background-color: transparent;
  border: 1px solid #4CAF50;
  color: #4CAF50;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #4CAF50;
  color: #000000;
}

.topic {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1rem;
}

.chartContainer {
  background-color: #111111;
  padding: 0px 10px;
  border-radius: 5px;
  height: 150px;
  margin-bottom: 20px;
}

.contentSection {
  flex: 1;
  position: relative;
  overflow-y: auto;
  padding-bottom: 80px;
}

.mainContent {
  height: 100%;
  padding-right: 5px;
}

/* Apply same background to both containers */
.rfdContainer,
.summaryContainer {
  background-color: #111111;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.rfdContainer h4,
.summaryContainer h4 {
  color: #4CAF50;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
}

.rfdContainer p,
.summaryContainer p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.summaryContainer h1,
.summaryContainer h2,
.summaryContainer h3,
.summaryContainer h4,
.summaryContainer h5,
.summaryContainer h6 {
  color: #4CAF50;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.paragraph {
  margin-bottom: 10px;
}

.list {
  margin-left: 20px;
  margin-bottom: 10px;
  list-style-type: disc;
}

.listItem {
  margin-bottom: 5px;
}

.summaryContainer a {
  color: #4CAF50;
  text-decoration: none;
}

.summaryContainer a:hover {
  text-decoration: underline;
}

.summaryContainer code {
  background-color: #111111;
  padding: 2px 4px;
  border-radius: 3px;
}

.summaryContainer pre {
  background-color: #111111;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.summaryContainer blockquote {
  border-left: 3px solid #4CAF50;
  padding-left: 10px;
  margin-left: 0;
  color: #CCCCCC;
}

.signupPrompt {
  text-align: center;
  margin-top: 2rem;
}

.startButton {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 1rem 0;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.loginLink {
  color: #4CAF50;
  cursor: pointer;
}

.loginLink:hover {
  text-decoration: underline;
}

.contentSectionMinimal {
  padding-bottom: 0;
}

.blurred {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
  transition: filter 0.3s ease;
}

.paywallOverlay {
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
  overflow: visible;
}

.paywallContainer {
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 120px;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  border: 1px solid #333;
  border-radius: 12px;
}

.evaluationsCounter {
  margin-bottom: 1.5rem;
}

.counterValue {
  font-size: 3rem;
  font-weight: bold;
  color: #4CAF50;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.counterLabel {
  color: #999;
  font-size: 0.9rem;
}

.revealButton,
.purchaseButton {
  width: 100%;
  padding: 0.6rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  white-space: nowrap;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.revealButton {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.revealButton:hover {
  transform: translateY(-2px);
  background-color: #45a049;
}

.purchaseButton.single {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.purchaseButton.single:hover {
  background-color: #45a049;
}

.purchaseButton.premium {
  background: linear-gradient(to right, #F6D365, #FFD700, #FDB813);
  color: black;
  border: none;
  font-weight: 600;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
}

.purchaseButton.premium:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
}

.revealIcon,
.purchaseIcon {
  width: 18px;
  height: 18px;
}

.goldPromotion {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #333;
  text-align: left;
}

.goldHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.goldHeader h3 {
  font-size: 1.25rem;
  margin: 0;
  background: linear-gradient(135deg, #E6B800, #FFD700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.goldIcon {
  color: #FFD700;
}

.goldDescription {
  color: #999;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.goldFeatures {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.goldFeatures li {
  color: #ccc;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.goldFeatures li::before {
  content: "✓";
  color: #FFD700;
}

.goldButton {
  width: 100%;
  padding: 0.75rem;
  background: linear-gradient(135deg, #E6B800, #FFD700);
  border: none;
  border-radius: 6px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.goldButton:hover {
  transform: translateY(-2px);
}

/* Purchase View Styles */
.purchaseOptions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  padding-bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.purchaseCard {
  background: rgba(26, 26, 26, 0.6);
  border: 1px solid #333;
  border-radius: 8px;
  padding: 1.25rem;
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
}

.purchaseCard:hover {
  transform: translateY(-2px);
}

.purchaseCard.premium {
  border: 1px solid var(--gold-primary);
  background: linear-gradient(165deg, #0e0c05 0%, #000000 100%);
  box-shadow: 0 0 20px var(--gold-glow);
}

.purchaseTitle {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.purchasePrice {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: var(--gold-primary);
}

.purchaseDescription {
  color: #999;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4;
}

.countdownContainer {
  text-align: center;
  margin-bottom: 0rem;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.countdownValue {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--gold-primary);
  line-height: 1;
  margin-bottom: 0.5rem;
}

.countdownLabel {
  color: #999;
  font-size: 0.875rem;
}

.fixedBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  border-top: 1px solid #333;
  padding: 0.75rem 2rem;
  z-index: 11;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.actionButton {
  background-color: transparent;
  border: 1px solid #333;
  padding: 0.5rem 1.25rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Manrope', sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.actionButton.feedback {
  border-color: #ff9800;
  color: #ff9800;
}

.actionButton.feedback:hover {
  background-color: #ff9800;
  color: #000;
}

.actionButton.flag {
  border-color: #a33;
  color: #a33;
}

.actionButton.flag:hover {
  background-color: #a33;
  color: #fff;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.flagModal {
  background-color: #1a1a1a;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  z-index: 2001;
}

.flagModal h3 {
  margin-top: 0;
  color: #fff;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.flagTextarea {
  width: 100%;
  min-height: 80px;
  background-color: #111;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0.75rem;
  color: #fff;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 0.75rem;
  resize: vertical;
  font-size: 0.875rem;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.modalButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;
  transition: opacity 0.2s;
}

.cancelButton {
  background-color: #333;
  color: #999;
}

.submitButton {
  background-color: #a33;
  color: white;
}

.modalButton:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .fixedBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    border-top: 1px solid #333;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .actionButtons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .actionItem {
    margin: 0.25rem 0;
    display: flex;
    justify-content: center;
  }

  .actionButton {
    padding: 0.5rem;
    font-size: 0.75rem;
    gap: 0.25rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .purchaseTitle {
    font-size: 0.9rem;
  }

  .purchasePrice {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .purchaseDescription {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .goldIcon,
  .purchaseIcon,
  .revealIcon {
    width: 14px;
    height: 14px;
  }

  .paywallOverlay {
    position: absolute;
    top: 15vh;
    left: 0;
    right: 0;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    overflow: visible;
  }

  .purchaseCard {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .countdownValue {
    font-size: 2rem;
  }

  .countdownLabel {
    font-size: 0.75rem;
  }
}