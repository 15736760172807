.setupPanelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px 20px;
  min-height: calc(100vh - 80px);
  background-color: #000000;
  font-family: 'Manrope', sans-serif;
}

.setupPanel {
  position: relative;
  width: 90vw;
  max-width: 600px;
  min-height: 400px;
  max-height: 90vh;
  overflow: hidden;
  padding: 1.5rem;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.setupHeading {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.panelContent {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 1.5rem;
}

.section h3 {
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  color: #fff;
  text-transform: lowercase;
}

.textarea {
  width: 100%;
  max-width: 850px;
  padding: 0.6rem;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-family: 'Manrope', sans-serif;
  line-height: 1.5;
  resize: none;
  overflow-y: auto;
  max-height: calc(1.5rem * 3);
  transition: border-color 0.3s ease;
}

.textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

.topicInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.autoSelect {
  position: absolute;
  right: 10px;
  color: #4CAF50;
  cursor: pointer;
  background-color: #1a1a1a;
  padding: 0.25rem 0.75rem;
  border-radius: 3px;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}

.autoSelect:hover {
  background-color: #333;
}

.formatInfo {
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  color: #999;
  font-size: 0.9rem;
  line-height: 1.4;
}

.formatInfo p {
  margin: 0.5rem 0;
}

.divider {
  border: none;
  border-top: 1px solid #333;
  margin: 1.5rem 0;
}

.sideButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
}

.sideButton {
  padding: 0.75rem;
  background-color: #000000;
  border: 2px solid transparent;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: lowercase;
}

.forButton:hover,
.forButton.active {
  border-color: #3a3;
  background-color: rgba(58, 170, 58, 0.1);
}

.againstButton:hover,
.againstButton.active {
  border-color: #a33;
  background-color: rgba(170, 51, 51, 0.1);
}

.randomButton:hover,
.randomButton.active {
  border-color: #c18910;
  background-color: rgba(193, 137, 16, 0.1);
}

.startButtonContainer {
  margin-top: auto;
  padding-top: 1.5rem;
  text-align: center;
}

.startButton {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  text-transform: lowercase;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.startButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.errorMessage {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff6b6b;
  padding: 0.75rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .setupPanelWrapper {
    padding: 60px 10px 10px;
  }
  
  .setupPanel {
    width: 100%;
    padding: 1rem;
  }
  
  .setupHeading {
    font-size: 1.2rem;
  }

  .section h3 {
    font-size: 1rem;
  }

  .sideButton {
    padding: 0.6rem;
    font-size: 0.85rem;
  }

  .startButton {
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
  }

  .formatInfo {
    font-size: 0.85rem;
  }

  .textarea {
    padding: 0.5rem;
    font-size: 0.85rem;
  }
}