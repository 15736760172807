/* EvaluationTooltip.module.css */
.tooltipContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  z-index: 10000;
}

.tooltipTrigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 0.75rem;
  cursor: help;
  padding: 0rem 0rem;
  border-radius: 4px;
  transition: color 0.2s ease;
}

.tooltipTrigger:hover {
  color: #999;
}

.tooltipContent {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 6px;
  padding: 1rem;
  width: 280px;
  display: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.tooltipContainer:hover .tooltipContent {
  display: block;
}

.tooltipContent::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background: #1a1a1a;
  border-left: 1px solid #333;
  border-top: 1px solid #333;
}

.tooltipContent h4 {
  color: #4CAF50;
  margin: 0 0 0.75rem 0;
  font-size: 0.875rem;
  text-align: center;
}

.scoreSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.scoreRange {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.scoreLabel {
  color: #4CAF50;
  font-weight: bold;
  min-width: 35px;
}

.scoreDesc {
  color: #999;
  font-size: 0.75rem;
}

.divider {
  height: 1px;
  background: #333;
  margin: 0.75rem 0;
}

.criteriaSection {
  font-size: 0.75rem;
}

.criteriaSection p {
  color: #999;
  margin: 0 0 0.5rem 0;
}

.criteriaSection ul {
  margin: 0;
  padding-left: 1.25rem;
  color: #999;
}

.criteriaSection li {
  margin-bottom: 0.25rem;
}

.tooltipBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 19999;
}

@media (max-width: 768px) {
  .tooltipContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    max-width: 300px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 20000;
  }

  .tooltipContent::before {
    display: none;
  }

  .tooltipContainer:hover .tooltipContent {
    display: none;
  }
}