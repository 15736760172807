.countdownTimer {
    text-align: center;
    margin-bottom: 0rem;
    animation: fadeIn 0.5s ease-out;
  }
  
  .timeDisplay {
    font-size: 1.4rem;
    font-weight: 800;
    color: var(--gold-primary);
    line-height: 1;
    margin-bottom: 0rem;
    font-family: 'Manrope', sans-serif;
    text-shadow: 0 0 10px var(--gold-glow);
  }
  
  .label {
    color: #999;
    font-size: 0.875rem;
    font-family: 'Manrope', sans-serif;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }