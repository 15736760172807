/* FactCheckResult.module.css */
.resultCard {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 30px;
    margin-top: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: 'Manrope', sans-serif;
  }
  
  .claim {
    font-size: 1.5rem;
    margin-bottom: 25px;
    color: #ffffff;
    text-align: center;
    text-transform: lowercase;
  }
  
  .meterContainer {
    margin-bottom: 30px;
  }
  
  .meterLabels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    text-transform: lowercase;
  }
  
  .falseLabel, .trueLabel {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .falseLabel {
    color: #f44336;
  }
  
  .trueLabel {
    color: #4CAF50;
  }
  
  .meter {
    height: 20px;
    background-color: #333;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .meterFill {
    height: 100%;
    background: linear-gradient(to right, #f44336, #4CAF50);
    transition: width 0.5s ease-in-out;
  }
  
  .meterMarker {
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #ffffff;
    transform: translateX(-50%);
  }
  
  .confidenceScore {
    text-align: center;
    margin-top: 10px;
    font-size: 1rem;
    color: #bbbbbb;
    text-transform: lowercase;
  }
  
  .basicFactNote {
    font-style: italic;
    color: #bbbbbb;
    margin-top: 20px;
    text-align: center;
    text-transform: lowercase;
  }
  
  .sourcesContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .sourcePanel {
    width: 48%;
  }
  
  .sourcePanel h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    text-transform: lowercase;
  }
  
  .falsePanel h3 {
    color: #f44336;
  }
  
  .truePanel h3 {
    color: #4CAF50;
  }
  
  .source {
    background-color: #222222;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .sourceTitle {
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
    text-transform: lowercase;
  }
  
  .falsePanel .sourceTitle {
    color: #f44336;
  }
  
  .truePanel .sourceTitle {
    color: #4CAF50;
  }
  
  .sourceTitle:hover {
    text-decoration: underline;
  }
  
  .sourceSummary {
    font-size: 0.9rem;
    color: #bbbbbb;
    margin-bottom: 10px;
    text-transform: lowercase;
  }
  
  .sourceScore {
    font-size: 0.9rem;
    font-weight: bold;
    color: #ffffff;
    text-transform: lowercase;
  }
  
  @media (max-width: 768px) {
    .sourcesContainer {
      flex-direction: column;
    }
  
    .sourcePanel {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .content {
      padding: 60px 15px 15px;
    }
  
    .content h1 {
      font-size: 2rem;
    }
  
    .input, .button {
      font-size: 0.9rem;
    }
  }