.textarea {
    min-height: 4rem;
    width: 100%;
    overflow: hidden;
    resize: none;
    border-radius: 5px;
    background-color: #1a1a1a;
    border: 1px solid #333;
    padding: 0.75rem;
    color: #FFFFFF;
    font-size: 1rem;
    transition: height 0.1s ease-out, border-color 0.3s;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .textarea:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Match your existing font */
  .textarea {
    font-family: 'Manrope', sans-serif;
  }