.setupPanelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  font-family: 'Manrope', sans-serif;
  padding: 1.6rem;
}

.signupPrompt {
  text-align: center;
  margin-top: 2rem;
}

.startButton {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 1rem 0;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.loginLink {
  color: #4CAF50;
  cursor: pointer;
}

.loginLink:hover {
  text-decoration: underline;
}

.setupPanel {
  position: relative;
  width: 90vw;
  max-width: 700px;
  min-height: 400px;
  max-height: 90vh;
  overflow: hidden;
  padding: 2.5rem;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.motionRow {
  font-family: 'Manrope', sans-serif;
  margin-top: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.motionLabel {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
}

.modeSelect {
  width: auto;
  padding: 0.75rem 1.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: 'Manrope', sans-serif;
  color: white;
  background-color: #1a1a1a;
  border: 1px solid #333;
}

.infoButton svg,
.confirmButton svg,
.editButton svg {
  transform: translateY(2px);
}

.setupHeading {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.panelContent {
  flex-grow: 1;
  overflow-y: visible;
  padding-right: 1.5rem;
  margin-bottom: 2rem;
}

.section {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 1.1rem;
  margin-bottom: 1.25rem;
}

.input,
select.input {
  padding: 0.75rem;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.input {
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.2em auto;
  padding-right: 2.5em;
}

.input:focus {
  outline: none;
  border-color: #4CAF50;
}

.topicInputContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 850px;
  margin-bottom: 1.5rem;
}

.buttonContainer {
  margin-top: 0rem;
}

.sideButtons {
  display: flex;
  gap: 1.5rem;
  height: 120px;
  margin-bottom: 2rem;
}

.forButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope', sans-serif;
}

.againstButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope', sans-serif;
}

.sideButton {
  flex: 1;
  padding: 0.75rem;
  background-color: #000000;
  border: 2px solid transparent;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.4rem;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

.randomButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  position: relative;
  overflow: hidden;
}

.randomButton::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    45deg,
    #3a3, #3a3,
    #a33, #a33,
    #3a3, #3a3,
    #a33, #a33
  );
  background-size: 400% 400%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.randomButton:hover::before,
.randomButton.active::before {
  opacity: 1;
  animation: gradientMove 3s ease infinite;
}

.randomButton::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #000000;
  border-radius: 3px;
  z-index: 2;
}

.randomButton h3 {
  position: relative;
  z-index: 3;
  margin: 0;
}

.randomButton h3:first-child {
  font-size: 2rem;
  line-height: 1;
}

.randomButton h3:last-child {
  font-size: 1rem;
  margin-top: 0.2rem;
  font-weight: normal;
  text-transform: uppercase;
}

@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.forButton:hover, .forButton.active {
  border-color: #3a3;
}

.againstButton:hover, .againstButton.active {
  border-color: #a33;
}

.sideButton:hover, .sideButton.active {
  background-color: #000000;
  color: #FFFFFF;
}

.infoButton,
.confirmButton,
.editButton {
  flex: 1;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40%;
  margin: 0.5rem 0;
}

.infoButton {
  background-color: #808080;
}

.confirmButton {
  background-color: #4CAF50;
}

.editButton {
  background-color: #869300;
}

.startButtonContainer {
  margin-top: 3rem;
  text-align: center;
}

.startButton {
  padding: 1.5rem 3.5rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.startButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.divider {
  border: none;
  border-top: 1px solid #333;
  margin: 0;
}

.rerollButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.rerollButton:hover:not(:disabled) {
  background-color: #45a049;
}

.rerollButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.popup {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 3rem;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popupTitle {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #4CAF50;
}

.infoBullets {
  list-style-type: none;
  padding: 0;
}

.infoBullets li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.6;
}

.infoBullets li::before {
  content: "•";
  color: #4CAF50;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

@media (max-width: 768px) {
  .setupPanel {
    width: 100vw;
    height: 100%;
    max-height: none;
    border-radius: 0;
    padding: 1.5rem;
  }

  .setupHeading {
    font-size: 1.25rem;
  }

  .sideButtons {
    height: 100px;
    gap: 1rem;
  }

  .startButton {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }

  .infoButton {
    width: 50%
  }

  .popup {
    padding: 2rem;
  }
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupHidden {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.popupVisible {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}