.card {
  background-color: black;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  min-height: 70px;
  font-family: 'Manrope', sans-serif;
}

.winningCard {
  border: 1px solid #22c55e;
}

.losingCard {
  border: 1px solid #ef4444;
}

.tieCard {
  position: relative;
  border: 1px solid;
  border: 1px solid #9ca3af;
}


.cardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date {
  font-size: 0.75rem;
  color: #9ca3af;
  font-family: 'Manrope', sans-serif;
}

.statusGroup {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 12px;
}

.statusItem {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #9ca3af;
  font-size: 0.75rem;
  font-family: 'Manrope', sans-serif;
}

.statusItem:first-child {
  padding-right: 12px;
  border-right: 1px solid #374151;
}

.statusText {
  font-size: 0.75rem;
  font-family: 'Manrope', sans-serif;
}

.statusDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
}

.won {
  color: #22c55e;
}

.lost {
  color: #ef4444;
}

.tie {
  color: #9ca3af;
}

.lockIcon {
  color: currentColor;
}

.topic {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.opponent {
  font-size: 0.75rem;
  color: #9ca3af;
  font-family: 'Manrope', sans-serif;
}

.opponentName {
  color: white;
}

.viewLink {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75rem;
}

/* Menu Styles */
.menuContainer {
  position: relative;
}

.menuButton {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
}

.dots {
  color: #9ca3af;
  font-size: 9px;
  letter-spacing: 1px;
  line-height: 0;
  transform: rotate(90deg);
  display: inline-block;
}

.menu {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 4px;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 4px 0;
  min-width: 120px;
  z-index: 10;
}

.menuItem {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  color: #fff;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
}

.menuItem:hover {
  background-color: #333;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #1a1a1a;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  animation: modalFadeIn 0.2s ease-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modalContent {
  padding: 28px;
}

.modalTitle {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: 'Manrope', sans-serif;
  letter-spacing: -0.01em;
}

.modalText {
  color: #9ca3af;
  font-size: 0.9375rem;
  margin-bottom: 28px;
  line-height: 1.5;
  font-family: 'Manrope', sans-serif;
}

.modalError {
  color: #ef4444;
  font-size: 0.875rem;
  margin-bottom: 16px;
  font-family: 'Manrope', sans-serif;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modalCancelButton,
.modalDeleteButton {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.9375rem;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  transition: all 0.2s ease;
}

.modalCancelButton {
  background-color: transparent;
  color: #fff;
  border: 1px solid #404040;
}

.modalCancelButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #525252;
}

.modalDeleteButton {
  background-color: transparent;
  color: #ef4444;
  border: 1px solid #ef4444;
}

.modalDeleteButton:hover {
  background-color: #ef4444;
  color: white;
}

@media (min-width: 768px) {
  .menu {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}