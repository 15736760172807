.setupPanelWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #000000;
  font-family: 'Manrope', sans-serif;
  padding: 1rem 0;
}

.textarea {
  width: 100%;
  max-width: 850px;
  padding: 0.75rem;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1rem; /* Retain your original font size */
  font-family: 'Manrope', sans-serif; /* Use your original font */
  line-height: 1.5; /* Adjust for consistent spacing */
  resize: none; /* Prevent manual resizing */
  overflow-y: auto; /* Enable scrollbar only when content exceeds max height */
  max-height: calc(1.5rem * 3); /* Limit height to 3 lines */
}

.textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

.setupPanel {
  position: relative;
  width: 90vw;
  max-width: 600px;
  overflow: visible;
  padding: 0.6rem;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.setupHeading {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 0rem;
}

.panelContent {
  flex-grow: 0;
  overflow-y: visible;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
}

.input,
select.input,
.topicInputContainer {
  width: 100%;
  max-width: 850px;
}

.input,
select.input {
  padding: 0.75rem;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.input {
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.2em auto;
  padding-right: 2.5em;
}

.input:focus {
  outline: none;
  border-color: #4CAF50;
}

.topicInputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.autoSelect {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #4CAF50;
  cursor: pointer;
  background-color: #1a1a1a;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
}

.trending {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.trendingLabel {
  font-weight: bold;
  color: #4CAF50;
  margin-right: 1rem;
}

.trendingTopic {
  padding: 0.25rem 0.5rem;
  background-color: #333;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 0.875rem;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out, transform 0.5s ease-in-out;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trendingTopic.animating {
  opacity: 0;
  transform: translateY(10px);
}

.trendingTopic:hover {
  transform: translateY(-2px);
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translateY(10px); }
  10%, 90% { opacity: 1; transform: translateY(0); }
}

.divider {
  border: none;
  border-top: 1px solid #333;
  margin-top: 1rem;
  margin-bottom: -0.25rem;
}

.personaGrid {
  display: grid;
  padding: 0.1rem;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.personaCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.personaCell:hover {
  background-color: #333;
}

.personaCell.selected {
  background-color: #2a2a2a;
  box-shadow: 0 0 0 2px #4CAF50;
}

.personaImageContainer {
  position: relative;
  width: 50px;
  height: 50px;
  margin-bottom: 0.5rem;
}

.personaAvatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.lockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.locked {
  opacity: 0.7;
}

.locked .personaName,
.locked .personaDifficulty {
  color: #666;
}

.personaName {
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 0.125rem;
}

.personaDifficulty {
  font-size: 0.625rem;
  font-weight: bold;
}

.easy {
  color: #4CAF50;
}

.moderate {
  color: #FFA500;
}

.hard {
  color: #FF4500;
}

.paginationControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.paginationControls button {
  padding: 0.25rem 0.5rem;
  background-color: #333;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.75rem;
}

.paginationControls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.signupPrompt {
  text-align: center;
  margin-top: 2rem;
}

.loginLink {
  color: #4CAF50;
  cursor: pointer;
}

.loginLink:hover {
  text-decoration: underline;
}

.sideButtons, 
.examineToggleButtons {
  display: flex;
  gap: 1rem;
}

.sideButton, 
.examButton {
  flex: 1;
  padding: 0.75rem;
  background-color: #000000;
  border: 2px solid transparent;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1rem;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

.forButton:hover, 
.forButton.active {
  border-color: #3a3;
}

.againstButton:hover, 
.againstButton.active {
  border-color: #a33;
}

.examinerButton:hover, 
.examinerButton.active {
  border-color: #c18910;
}

.examineeButton:hover, 
.examineeButton.active {
  border-color: purple;
}

.sideButton:hover, 
.sideButton.active,
.examButton:hover, 
.examButton.active {
  background-color: #000000;
  color: #FFFFFF;
}

.forButton:hover, 
.forButton.active {
  background-color: #000000;
}

.againstButton:hover, 
.againstButton.active {
  background-color: #000000;
}

.examinerButton:hover, 
.examinerButton.active {
  background-color: #000000;
}

.examineeButton:hover, 
.examineeButton.active {
  background-color: #000000;
}

.startButtonContainer {
  margin-top: auto;
  padding-top: 2rem;
  text-align: center;
}

.startButton {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.startButton:hover:not(:disabled) {
  background-color: #45a049;
}

.startButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  h3 {
      font-size: 1.1rem;
  }

  .setupPanel {
      width: 100vw;
      height: auto;
      padding: 1rem;
  }
  
  .setupHeading {
      font-size: 1.25rem;
  }
  
  .personaGrid {
      grid-template-columns: repeat(2, 1fr);
  }
  
  .startButton {
      font-size: 1.1rem;
      padding: 0.75rem 1.25rem;
  }

  .setupPanelWrapper {
      padding: 0.5rem 0;
  }
}

.personaCell.isGold {
  box-shadow: 0 0 0 1px rgba(255, 215, 0, 0.3);
}

/* If both selected and gold, we want both effects */
.personaCell.selected.isGold {
  box-shadow: 0 0 0 2px #4CAF50, 0 0 0 4px rgba(255, 215, 0, 0.3);
}