/* Container and Main Layout */
.container {
  min-height: 100vh;
  background-color: black;
  color: white;
  font-family: 'Manrope', sans-serif;
}

.mainContent {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: calc(1rem + env(safe-area-inset-top))
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.25rem;
  color: white;
  font-family: 'Manrope', sans-serif;
}

/* Profile Card */
.profileCard {
  max-width: 64rem;
  margin: 0 auto 2rem;
  background-color: black;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.profileLayout {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .profileLayout {
    flex-direction: row;
  }
}

/* Avatar Section */
.avatarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.avatarWrapper {
  width: 8rem;
  height: 8rem;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  border: 2px solid #1f2937;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.onlineStatus {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(17, 24, 39, 0.5);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
}

.onlineDot {
  width: 0.625rem;
  height: 0.625rem;
  background-color: #22c55e;
  border-radius: 9999px;
}

/* Info Section */
.infoSection {
  flex-grow: 1;
}

.nameRow {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  justify-content: flex-start;
  position: relative;
}

@media (min-width: 768px) {
  .nameRow {
    justify-content: flex-start;
  }
}

.nameRow h1 {
  font-size: 1.875rem;
  font-weight: 700;
  margin: 0;
  position: relative;
  padding: 0 25px;
}

/* Add golden glow animation only for gold users */
.nameRow h1[data-is-gold="true"] {
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% {
    text-shadow: 
      0 0 10px rgba(255, 223, 0, 0.2),
      0 0 20px rgba(255, 223, 0, 0.2),
      0 0 30px rgba(255, 223, 0, 0.2);
  }
  50% {
    text-shadow: 
      0 0 20px rgba(255, 223, 0, 0.4),
      0 0 30px rgba(255, 223, 0, 0.4),
      0 0 40px rgba(255, 223, 0, 0.4);
  }
}

/* Crown and Sparkle styles */
.nameRow h1 span {
  position: relative;
  display: inline-block;
}

.crown {
  position: absolute;
  left: 11px;
  top: -8px;
  transform: rotate(-50deg);
  width: 20px;
  height: 15px;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.3));
  z-index: 1;
}

.crown svg {
  width: 100%;
  height: 100%;
  display: block;
}

/* Sparkle styles for gold users */
.nameRow h1[data-is-gold="true"]::before,
.nameRow h1[data-is-gold="true"]::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #FFD700;
  opacity: 0;
  animation: sparkle 3s ease-in-out infinite;
}

.nameRow h1[data-is-gold="true"]::before {
  left: 0;
  top: 50%;
  animation-delay: 0.5s;
}

.nameRow h1[data-is-gold="true"]::after {
  right: 0;
  top: 50%;
  animation-delay: 1s;
}

.nameRow h1[data-is-gold="true"] .sparkle-top,
.nameRow h1[data-is-gold="true"] .sparkle-bottom {
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #FFD700;
  opacity: 0;
}

.nameRow h1[data-is-gold="true"] .sparkle-top {
  left: 40%;
  top: -10px;
  animation: sparkleUp 3s ease-in-out infinite;
  animation-delay: 1.5s;
}

.nameRow h1[data-is-gold="true"] .sparkle-bottom {
  right: 40%;
  bottom: -10px;
  animation: sparkleDown 3s ease-in-out infinite;
  animation-delay: 2s;
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0) translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1) translate(10px, -10px);
  }
}

@keyframes sparkleUp {
  0%, 100% {
    opacity: 0;
    transform: scale(0) translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1) translate(5px, -15px);
  }
}

@keyframes sparkleDown {
  0%, 100% {
    opacity: 0;
    transform: scale(0) translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1) translate(-5px, 15px);
  }
}

.flag {
  width: 1.5rem;
  height: 1rem;
}

/* Status Section */
.statusSection {
  margin-bottom: 1.5rem;
}

.editStatus {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.statusInput {
  width: 100%;
  background-color: rgba(17, 24, 39, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;
  color: #d1d5db;
  font-family: 'Manrope', sans-serif;
  resize: none;
}

.statusInput:focus {
  outline: none;
  box-shadow: 0 0 0 2px #374151;
}

.statusButtons {
  display: flex;
  gap: 0.75rem;
}

.saveButton,
.cancelButton {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.saveButton {
  background-color: #1f2937;
  color: white;
}

.saveButton:hover {
  background-color: #374151;
}

.cancelButton {
  background-color: rgba(17, 24, 39, 0.5);
  color: #d1d5db;
}

.cancelButton:hover {
  background-color: rgba(17, 24, 39, 0.7);
}

.displayStatus {
  background-color: rgba(17, 24, 39, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;
  color: #9ca3af;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.displayStatus:hover {
  background-color: rgba(17, 24, 39, 0.4);
}

.editIcon {
  width: 1rem;
  height: 1rem;
  color: #6b7280;
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
}

/* Stats Grid */
.statsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .statsGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.statItem {
  background-color: rgba(17, 24, 39, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statIcon {
  color: #9ca3af;
  margin-bottom: 0.5rem;
}

.icon {
  color: #9ca3af;
}

.statLabel {
  font-size: 0.875rem;
  color: #9ca3af;
}

.statValue {
  font-weight: 500;
}

/* Tabs Section */
.tabsSection {
  max-width: 64rem;
  margin: 0 auto;
}

.tabsHeader {
  border-bottom: 1px solid #1f2937;
}

.tabButtons {
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
}

@media (min-width: 768px) {
  .tabButtons {
    justify-content: flex-start;
  }
}

.tabButton {
  padding: 1rem 2rem;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
  transition: all 0.2s;
  position: relative;
  color: #9ca3af;
  background: none;
  border: none;
  cursor: pointer;
}

.tabButton:hover {
  color: #d1d5db;
}

.activeTab {
  color: white;
  font-weight: 600;
}

.tabIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: white;
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.tabContentWrapper {
  width: 100%;
  padding: 1.5rem 0;
}

.tabContent {
  width: 100%;
}

/* Debates Section */
.debatesSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.noResults {
  text-align: center;
  color: #9ca3af;
}

.comingSoon {
  color: #9ca3af;
  font-family: 'Manrope', sans-serif;
}

/* Filter Controls */
.filterContainer {
  margin-bottom: 1.5rem;
}

.filterControls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .filterControls {
    flex-direction: row;
    align-items: center;
  }
}

.selectWrapper {
  position: relative;
  min-width: 140px;
  height: 2.5rem;
}

.selectIcon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
  pointer-events: none;
}

.select {
  height: 100%;
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 1rem;
  background-color: #111827;
  border-radius: 0.5rem;
  color: white;
  appearance: none;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  border: none;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #374151;
}