.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  padding: 1rem;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

.modal {
  position: relative;
  width: 100%;
  max-width: 36rem;
  max-height: calc(100vh - 80px);
  background: linear-gradient(to bottom, #000000, #18181b);
  border-radius: 1rem;
  border: 1px solid #ca8a04;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: auto;
}

.particles {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fbbf24;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 0 0 8px rgba(234, 179, 8, 0.5);
  animation: float 4s infinite ease-out;
}

@keyframes float {
  0%, 100% {
    opacity: 0;
    transform: translateY(0);
  }
  25%, 75% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    transform: translateY(-12px);
  }
}

.particle:nth-child(1) { top: 5%; left: -5px; }
.particle:nth-child(2) { top: 5%; right: -5px; }
.particle:nth-child(3) { top: 35%; left: -5px; }
.particle:nth-child(4) { top: 35%; right: -5px; }
.particle:nth-child(5) { top: 65%; left: -5px; }
.particle:nth-child(6) { top: 65%; right: -5px; }
.particle:nth-child(7) { top: 95%; left: -5px; }
.particle:nth-child(8) { top: 95%; right: -5px; }

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #71717a;
  transition: color 0.2s;
}

.closeButton:hover {
  color: #ffffff;
}

.content {
  padding: 1.5rem;
}

.header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.crownIcon {
  color: #fbbf24;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to right, #fbbf24, #b45309);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  color: #a1a1aa;
  font-size: 1rem;
}

.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.benefitCard {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 0.875rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #27272a;
  transition: border-color 0.3s;
}

.benefitCard:hover {
  border-color: rgba(202, 138, 4, 0.5);
}

.benefitIcon {
  color: #fbbf24;
}

.benefitIconWrapper {
  margin-top: 0.25rem;
}

.benefitContent {
  flex: 1;
}

.benefitTitle {
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.benefitDescription {
  color: #a1a1aa;
  font-size: 0.75rem;
}

.footer {
  text-align: center;
  margin-top: 1rem;
}

.footerText {
  font-size: 0.75rem;
  color: #71717a;
  margin-bottom: 0.75rem;
}

.startButton {
  padding: 0.625rem 1.75rem;
  background: linear-gradient(to right, #fbbf24, #b45309);
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: #000000;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.startButton:hover {
  filter: brightness(110%);
  transform: translateY(-2px);
}

@media (max-width: 640px) {
  .modal {
    max-width: 90%;
    margin: 0.5rem;
  }

  .benefitsGrid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .content {
    padding: 1.25rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 0.875rem;
  }

  .benefitCard {
    padding: 0.75rem;
  }
}